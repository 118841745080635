const getFirstDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1);

const getLastDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

const addZero = (day: number) => (day <= 9 ? '0' + day : day);

const toShortIsoDate = (date: Date) => {
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate()
  )}`;
};

const generateMonthPeriod = (date: Date) => {
  return {
    startDate: toShortIsoDate(getFirstDay(date)),
    endDate: toShortIsoDate(getLastDay(date)),
  };
};

export const getDatesFromPeriod = (period: string) => {
  let date = new Date();
  let lastDate = new Date();
  let startDate = '';
  let endDate = '';

  switch (period) {
    case 'last7Days':
      date.setDate(date.getDate() - 7);
      return {
        startDate: toShortIsoDate(date),
        endDate: toShortIsoDate(lastDate),
      };

    case 'last30Days':
      date.setDate(date.getDate() - 30);
      return {
        startDate: toShortIsoDate(date),
        endDate: toShortIsoDate(lastDate),
      };

    case 'month':
      return generateMonthPeriod(date);

    case 'lastMonth':
      date.setMonth(date.getMonth() - 1);
      return generateMonthPeriod(date);

    case 'twoMonthsAgo':
      date.setMonth(date.getMonth() - 2);
      return generateMonthPeriod(date);

    case 'threeMonthsAgo':
      date.setMonth(date.getMonth() - 3);
      return generateMonthPeriod(date);

    case 'fourMonthsAgo':
      date.setMonth(date.getMonth() - 4);
      return generateMonthPeriod(date);

    case 'fiveMonthsAgo':
      date.setMonth(date.getMonth() - 5);
      return generateMonthPeriod(date);

    case 'sixMonthsAgo':
      date.setMonth(date.getMonth() - 6);
      return generateMonthPeriod(date);
  }
  return {
    startDate,
    endDate,
  };
};

export const getMonth = (month: number) => {
  const aux = month <= 0 ? 12 + month : month;

  switch (aux) {
    case 1:
      return 'Janeiro';
    case 2:
      return 'Fevereiro';
    case 3:
      return 'Março';
    case 4:
      return 'Abril';
    case 5:
      return 'Maio';
    case 6:
      return 'Junho';
    case 7:
      return 'Julho';
    case 8:
      return 'Agosto';
    case 9:
      return 'Setembro';
    case 10:
      return 'Outubro';
    case 11:
      return 'Novembro';
    case 12:
      return 'Dezembro';
    default:
      throw new Error(`Non-existent month in switch: ${month}`);
  }
};
