// import { getToken } from 'utils/jwt';
import { getToken } from 'utils/jwt';
import api from './axios';
import { ProductModel } from './products';
import { UserModel } from './users';
import { swrFetcher } from './swr';
import useSWR from 'swr';

export interface PurchaseModel {
  createdAt: Date;
  meritus: number;
  productId: string;
  quantity: number;
  userId: string;
  status: string;
  id: string;
  additionalInformation: {
    observation: string;
  };
  product: ProductModel;
}

export const listPurchase = async () => {
  const { data } = await api.request({
    method: 'get',
    url: 'purchase/byUser',
  });
  return data;
};

export const listPurchaseByCompany = async (
  companyId: string
): Promise<PurchaseModel[]> => {
  const { data } = await api.request({
    method: 'get',
    url: `/purchase/byCompany/${companyId}`,
  });
  return data;
};

export const useSWRListPurchaseByCompany = (
  companyId: UserModel['companyId'] | undefined,
  params?: {
    offset?: number;
    limit?: number;
    companyId: UserModel['companyId'] | undefined;
  },
  requestKey: number = 0
) => {
  const { data, error } = useSWR<any>(
    companyId
      ? [`/purchase/byCompany/${companyId}`, { params }, requestKey]
      : null,
    swrFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );

  return {
    data: data?.data.data,
    metadata: data?.data.metadata,
    isLoading: !error && !data,
    isError: error,
  };
};

type RequestManagerPurchaseParams = {
  productId: string;
  users: string[];
  observation?: string;
  value: number;
};
export const requestManagerPurchase = async (
  params: RequestManagerPurchaseParams
) => {
  const { data } = await api.request({
    method: 'post',
    url: `managerPurchase/request/${params.productId}`,
    data: {
      users: params.users,
      observations: params.observation,
      value: params.value,
    }
  });
  return data;
};
