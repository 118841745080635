import useSWR from 'swr';
import api from './axios';
import { CompanyModel } from './company';
import { swrFetcher } from './swr';

export type UserModel = {
  id: string;
  companyId: string;
  registration: string;
  name: string;
  displayName: string;
  jobTitle: string;
  email: string;
  backupEmail: string;
  mobile: string;
  password: string;
  img: string;
  address: Address;
  selectedValues: string[];
  birthday: Date;
  bio: string;
  meritus: number;
  meritMoney: number;
  isDisabled?: boolean;
  groups: string[];
  // mainGroup: string[];
  meritMoneySchemeId: string;
  manager: string[];
  wallets: Array<{
    walletId: string;
    name: string;
    isDisabled: boolean;
    style: {
      theme: string;
    };
    id: string;
    fund: number;
    desc: string;
    companyId: string;
    categories: string[];
    fromTemplate: string;
  }>;
};

export type Address = {
  postalCode: string;
  street: string;
  numberAndComplement: string;
  city: string;
  state: string;
};

export enum UserStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

type getUserResponse = { value: UserModel };

export const getUser = async (userId: UserModel['id']) => {
  const {
    data: { value },
  } = await api.get<getUserResponse>(`user/${userId}`, {});
  return value;
};

export const updateUser = async (
  userId: UserModel['id'],
  params: Partial<UserModel>
) => {
  const response = await api.request({
    method: 'put',
    url: `user/${userId}`,
    data: params,
  });
  return response;
};

export type updateUserProfileParams = {
  meritMoneySchemeId: UserModel['meritMoneySchemeId'] | undefined;
  groups: UserModel['groups'] | undefined;
  manager: boolean;
};

export const updateUserProfile = async (
  userId: UserModel['id'],
  params: updateUserProfileParams
) => {
  const response = await api.request({
    method: 'put',
    url: `user/byManager/${userId}`,
    data: params,
  });
  return response;
};

export const userAuthenticate = async (params: {
  email: string;
  password: string;
}) => {
  const { data } = await api.request({
    method: 'post',
    url: 'login',
    data: params,
  });
  return data;
};

type finishRegistrationModel = {
  user: UserModel;
  jwt: string;
};

export const finishRegistration = async (
  inviteId: string,
  params: Omit<UserModel, 'id' | 'registration' | 'companyId' | 'name'>
) => {
  const { data } = await api.request<finishRegistrationModel>({
    method: 'post',
    url: `user/finishRegistration/${inviteId}`,
    data: params,
  });
  return data;
};

export type listDisplayNameResult = Array<{
  displayName: string;
  name: string;
  id: string;
  img: string;
  email: string;
}>;

export const listDisplayName = async (): Promise<listDisplayNameResult> => {
  const { data } = await api.request({
    method: 'get',
    url: `display-name`,
  });
  return data;
};

export const fileUpload = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await api.request({
    method: 'post',
    url: 'upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
  return data;
};

export const whoAmI = async (): Promise<{
  userId: string;
  manager?: string[];
}> => {
  const { data } = await api.request({
    method: 'get',
    url: `whoAmI`,
  });
  return data;
};

export const tokenIsValid = async (): Promise<boolean> => {
  try {
    await api.request({
      method: 'get',
      url: `validate-token`,
    });
    return true;
  } catch {
    return false;
  }
};

export const logoutApi = async (): Promise<boolean> => {
  try {
    await api.request({
      method: 'get',
      url: `logout`,
    });
    return true;
  } catch {
    return false;
  }
};

export const listUserByCompany = async (
  companyId: string
): Promise<UserModel[]> => {
  const { data } = await api.request({
    method: 'get',
    url: `/user/byCompany/${companyId}`,
  });
  return data;
};

export const useSWRListUserByCompany = (
  companyId: CompanyModel['id'] | undefined,
  params?: {
    offset?: number;
    limit?: number;
    userByName?: string;
    userByStatus?: UserStatus;
  },
  requestKey: number = 0
) => {
  const { data, error } = useSWR<any>(
    companyId ? [`/user/byCompany/${companyId}`, { params }, requestKey] : null,
    swrFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );

  return {
    data: data?.data.data,
    metadata: data?.data.metadata,
    isLoading: !error && !data,
    isError: error,
  };
};

export const enableUser = async (userId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/user/enable/${userId}`,
  });
  return data;
};

export const disableUser = async (userId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/user/disable/${userId}`,
  });
  return data;
};

export const disableUserWithAccess = async (
  userId: string,
  params: { newEmail: string }
): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/user/disableWithAccess/${userId}`,
    data: params,
  });
  return data;
};
