import React, { useContext } from 'react';
import { ReactComponent as MenuIcon } from '../assets/vectors/menu.svg';
import { ReactComponent as LogoIcon } from '../assets/vectors/logo.svg';
import { ReactComponent as AlternativeLogoIcon } from '../assets/vectors/logo-alternative.svg';
import { UserContext } from './Context/UserContext';
import { Link } from 'react-router-dom';
import GhostProfilePicture from './Ghost/GhostProfilePicture';

export interface NavbarProps {
  alternative?: boolean;
  hDivider?: boolean;
  vDivider?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ alternative, vDivider, hDivider }) => {
  const textColor = alternative ? 'text-white' : 'text-darker-90';
  const BgColor = alternative ? 'bg-primary-darker' : 'bg-white';
  const dividers = `${vDivider && 'divide-solid divide-x divide-opacity-50'} ${
    hDivider && 'border-b'
  }`;

  const { user } = useContext(UserContext);

  return (
    <nav
      className={`${BgColor} ${textColor} ${dividers} grid grid-cols-3 pt-2 sticky top-0 z-40`}
    >
      <div className="flex justify-between items-center col-span-2 px-3">
        <Link
          to="/menu"
          className="flex items-center gap-3"
        >
          {alternative ? <AlternativeLogoIcon /> : <LogoIcon />}
          <p className="flex flex-col md:flex-row md:justify-center text-base  md:text-3xl  ">meritu <span className="text-xs font-light pr-3 md:flex md:items-center md:ml-1">manager</span></p>
        </Link>
        <div className="flex gap-8 text-sm">
          <Link
            to="/"
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            <MenuIcon className="stroke-current" />
            <p className='text-base'>Menu</p>
          </Link>
          {/* <NotificationIcon className="stroke-current" /> */}
        </div>
      </div>

      <div className="flex flex-1 justify-between items-center mx-4">
        <div className="flex gap-3">
          {/* <div className="flex">
            <p>A distribuir: </p>
            <Tag alternative className="ml-1">{user?.meritMoney}</Tag>
          </div>
          <div className="flex">
            <p>Carteira: </p>
            <Tag inverted={alternative} className="ml-1">{user?.meritus}</Tag>
          </div> */}
        </div>
        <div className="flex items-center">
          <Link to="#">
            <div className="w-9">
              <GhostProfilePicture
                src={user?.img}
                className=""
                name={user?.displayName}
                alt=""
              />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
