import React from 'react';
import { Formik, Form } from 'formik';
import { updateGroup } from 'utils/api/group';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { toast } from 'react-toastify';
import { GroupModel } from 'utils/api/group'
import { createGroupSchema } from 'utils/validators/createGroup'

interface UpdateGroupProps {
  group: GroupModel | undefined;
  cancel: (refresh?: boolean) => void;
}

const UpdateGroup: React.FC<UpdateGroupProps> = ({ cancel, group }) => {
  const { user } = useContext(UserContext);
  
  if (group === undefined) {
    toast.error('Grupo inválido');
    cancel();
    return <></>;
  }

  const initialValues = {
    name: group.name,
    desc: group.desc
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { name, desc } = values;
    const processedValues = {
      name,
      desc
    };

    const promise = updateGroup(group.id, processedValues);
    toast.promise(promise, {
      pending: 'Atualizando grupo',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel(true);
          return 'Grupo atualizado';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded md:w-5/12">
      <Formik
        validationSchema={createGroupSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Editar grupo</p>
            <p className="mt-8">Nome:</p>
            <InputText 
                name="name" 
                placeholder="Digite o nome do grupo"
            />
            <p className="mt-8">Descrição:</p>
            <InputText
              name="desc"
              placeholder="Fale um pouco sobre o grupo"
            />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Atualizar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateGroup;