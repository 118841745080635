import React, { useState } from 'react';

import Navbar from 'Components/Navbar';
import CompanyStatistics from './CompanyStatistics';
import ActivityStatistics from './ActivityStatistics';
import CompanyValuesStatistics from './CompanyValuesStatistics';
import Reports from './Reports'
interface StatisticsProps {}

const Statistics: React.FC<StatisticsProps> = () => {
  const [tab, setTab] = useState('company');

  return (
    <div>
      <Navbar />
      <div className="px-10 mt-7">
        <p className="text-xs">
          <span className="text-primary">Estatísticas</span>
        </p>
        <div className="">
          <p className="text-3xl mt-4 text-black">Estatísticas</p>
        </div>
        <div className="mt-16 shadow px-7 pb-7">
          <div className="flex gap-4 border-b border-lighter-70 py-1 px-3 mb-5 mt-2">
            <button
              className={`${
                tab === 'company' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('company')}
            >
              Empresa
            </button>
            <button
              className={`${
                tab === 'activity' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('activity')}
            >
              Atividade
            </button>
            <button
              className={`${
                tab === 'values' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('values')}
            >
              Valores
            </button>
            <button
              className={`${
                tab === 'reports' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('reports')}
            >
              Relatórios
            </button>
          </div>
          {tab === 'company' ? (
            <CompanyStatistics />
          ) : tab === 'activity' ? (
            <ActivityStatistics />
          ) : tab === 'values' ? (
            <CompanyValuesStatistics /> 
          ) : (
            <Reports/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
