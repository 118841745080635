import React from 'react';
import { Formik, Form } from 'formik';
import SelectField, { SelectOptions } from 'Components/Formik/SelectField';
import { useEffect } from 'react';
import { useState } from 'react';
import { getCompany } from 'utils/api/company';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import SelectMultiField from './Formik/SelectMultiField';
import { createUserSchema } from 'utils/validators/createUser';
import { updateInvite, UserInviteModel } from 'utils/api/invites';
import { toast } from 'react-toastify';
import { listMeritMoneySchemeByCompany } from 'utils/api/meritMoneyScheme';

interface UpdateInviteProps {
  invite: UserInviteModel | undefined;
  cancel: (refresh?: boolean) => void;
}

const UpdateInvite: React.FC<UpdateInviteProps> = ({ cancel, invite }) => {
  const { user } = useContext(UserContext);

  const [groupsOptions, setGroupsOptions] = useState<SelectOptions>([]);
  const [meritMoneySchemeOptions, setMeritMoneySchemeOptions] = useState<SelectOptions>([]);

  useEffect(() => {
    //get company groups
    if (!user?.companyId) return;
    getCompany(user?.companyId).then((company) => {
      const groups = company.groups;
      if (groups === undefined) return setGroupsOptions([]);
      const options = groups.map((group) => ({
        label: group.name,
        value: group._id,
      }));
      setGroupsOptions(options);
    });

    listMeritMoneySchemeByCompany(user.companyId).then((meritMoneySchemeList) => {
      const options = meritMoneySchemeList.map((meritMoneyScheme) => ({
        label: meritMoneyScheme.name,
        value: meritMoneyScheme.id,
      }));
      setMeritMoneySchemeOptions(options)
    })
  }, [user]);

  if (invite === undefined) {
    toast.error('Convite inválido');
    cancel();
    return <></>;
  }

  const initialValues = {
    name: invite.name,
    email: invite.email,
    // mainGroup: invite.mainGroup,
    meritMoneySchemeId: invite.meritMoneySchemeId,
    groups: [] as SelectOptions,
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    console.log('aaaaaaa');
    
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { groups, meritMoneySchemeId, name } = values;
    const processedValues = {
      groups: groups.map((x) => x.value),
      meritMoneySchemeId,
      name,
    };

    const promise = updateInvite(invite.id, processedValues);
    toast.promise(promise, {
      pending: 'Atualizando convite',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel(true);
          return 'Convite Atualizado';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded w-5/12">
      <Formik
        validationSchema={createUserSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Editar convite</p>
            <p className="mt-8">Nome:</p>
            <InputText name="name" placeholder="Digite o nome do usuário" />
            <p className="mt-8">Email:</p>
            <InputText
              name="email"
              placeholder="Digite o email do usuário"
              disabled
            />
            <p className="mt-8">Esquema de merit money:</p>
            <SelectField
              name="meritMoneySchemeId"
              placeholder="Selecione um esquema de merit money"
              options={meritMoneySchemeOptions}
              className="mt-2"
            />
            <p className="mt-8">Grupos:</p>
            <SelectMultiField
              name="groups"
              options={groupsOptions}
              className="mt-2"
              initialValues={invite.groups}
            />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Atualizar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateInvite;
