import api from './axios';
import { CompanyModel } from './company';

export type CompanyTotalizer = {
  userWalletTotalizer: number;
  meritMoneyAvailable: number;
  registeredUsers: number;
  activeUsers: number;
  pendingInvites: number;
};

export type ActivityTotalizer = {
  activeUsers: number;
  userRecognitionsMade: number;
  recognitionsMade: number;
  recognitionsReceived: number;
  userRecognitionsReceived: number;
  meritMoneyDitributed: number;
  meritusReceived: number;
  totalUser: number;
  userDisabledOnPeriod: number;
};

type ActivityTotalizerParams = {
  dates: {
    startDate: string;
    endDate: string;
  };
  groups: string[];
};

type ValuesTotalizerParams = {
  dates: {
    startDate: string;
    endDate: string;
  };
  receiverGroups: string[];
  senderGroups: string[];
};

export type CompanyValuesTotalizer = Array<{
  companyValue: string;
  count: number;
  value: number;
}>;

export const getCompanyTotalizer = async (companyId: CompanyModel['id']) => {
  const { data } = await api.get<CompanyTotalizer>(
    `totalizer/company/${companyId}`,
    {}
  );
  return data;
};

const now = new Date();
const lastDay = new Date();
export const getActivityTotalizer = async (
  companyId: CompanyModel['id'],
  params: ActivityTotalizerParams = {
    dates: {
      startDate: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`,
      endDate: `${lastDay.getFullYear()}-${lastDay.getMonth()}-${lastDay.getDate()}`,
    },
    groups: [],
  }
) => {
  const { data } = await api.get<ActivityTotalizer>(
    `totalizer/activity/${companyId}?startDate=${
      params?.dates.startDate
    }&endDate=${params?.dates.endDate}
    ${params?.groups.reduce((acc, group) => `${acc}&groups=${group}`, '')}`,
    {}
  );
  return data;
};

export const getCompanyValuesTotalizer = async (
  companyId: CompanyModel['id'],
  params: ValuesTotalizerParams = {
    dates: {
      startDate: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`,
      endDate: `${lastDay.getFullYear()}-${lastDay.getMonth()}-${lastDay.getDate()}`,
    },
    receiverGroups: [],
    senderGroups: [],
  }
) => {
  const { data } = await api.get<CompanyValuesTotalizer>(
    `totalizer/companyValues/${companyId}?startDate=${
      params?.dates.startDate
    }&endDate=${params?.dates.endDate}
    ${params?.receiverGroups.reduce(
      (acc, group) => `${acc}&receiverGroups=${group}`,
      ''
    )}
    ${params?.senderGroups.reduce(
      (acc, group) => `${acc}&senderGroups=${group}`,
      ''
    )}
    `,
    {}
  );
  return data;
};
