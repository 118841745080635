import React from 'react';
import './animation.css';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ children, className }) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
