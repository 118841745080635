import { getToken } from 'utils/jwt';
import api from './axios';
import { CompanyModel } from './company';
import { UserModel } from './users';

export type UserInviteModel = {
  id: string;
  companyId: CompanyModel['id'];
  name: UserModel['name'];
  createdAt: Date;
  email: string;
  status: 'expired' | 'pending' | 'complete' | 'resending' | 'invalidated';
  groups: string[];
  //mainGroup: string;
  meritMoneySchemeId: string;
};

export type RequestInviteModel = {
  companyId: CompanyModel['id'];
  name: UserModel['name'];
  email: string;
  groups: string[];
  // mainGroup: string;
  meritMoneySchemeId: string;
};

export const getInvite = async (userId: UserModel['id']) => {
  const { data } = await api.get<UserInviteModel>(`invite/${userId}`, {});
  return data;
};

export const requestInvite = async (payload: RequestInviteModel) => {
  const { data } = await api.request({
    url: `invite/inviteUser`,
    data: payload,
    method: 'POST',
  });
  return data;
};

export const listInvite = async (companyId: string) => {
  const { data } = await api.get<UserInviteModel[]>(
    `invite/byCompany/${companyId}`,
  );
  return data;
};

export const updateInvite = async (inviteId: string, params: any) => {
  await api.request({
    method: 'PUT',
    url: `invite/${inviteId}`,
    data: params,
  });
};

export const invalidateInvite = async (inviteId: string) => {
  await api.request({
    method: 'PUT',
    url: `invite/invalidate/${inviteId}`,
  });
};