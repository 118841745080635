import React, { useState, useEffect, useContext, useCallback } from 'react';
import UsersFilters from 'Components/Filters/UsersFilter';
import {
  UserModel,
  UserStatus,
  useSWRListUserByCompany,
} from 'utils/api/users';
import { UserContext } from 'Components/Context/UserContext';
import { UserDetailsTyoe } from 'Components/UserDetails';
import TableComponent, {
  TableComponentColumns,
} from 'Components/TableComponent';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';
import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';

interface UsersListProps {
  selectUserDetails: (
    user: UserModel,
    type?: UserDetailsTyoe,
    then?: (selectedUser: UserModel) => any
  ) => void;
  toggleRefreshData?: boolean;
  setAndShowUpdateUser: (user: UserModel, callback?: Function) => void;
  
}

const UsersList: React.FC<UsersListProps> = ({
  selectUserDetails,
  toggleRefreshData = false,
  setAndShowUpdateUser
}) => {
  const perPage = 15;

  const { user } = useContext(UserContext);

  const [prevToggleRefreshData, setPrevToggleRefreshData] =
    useState(toggleRefreshData);
  const [renderKey, setRenderKey] = useState(0);
  const [page, setPage] = useState(1);
  const [userName, setUserName] = useState('');
  const [userStatus, setUserStatus] = useState(UserStatus.ENABLED);

  const { data, metadata, isLoading } = useSWRListUserByCompany(
    user?.companyId,
    {
      offset: (page - 1) * perPage || 0,
      limit: perPage,
      userByName: userName,
      userByStatus: userStatus,
    },
    renderKey
  );

  const onChangePage = useCallback(async (page: number) => {
    setPage(page);
  }, []);

  const afterAction = () => {
    setRenderKey(Math.random());
  };

  useEffect(() => {
    if (toggleRefreshData === prevToggleRefreshData) {
      return;
    }

    setRenderKey(Math.random());
    setPrevToggleRefreshData(toggleRefreshData);
  }, [prevToggleRefreshData, toggleRefreshData]);

  const [showFilters, setShowFilters] = useState(false);
  const handleSetShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilters = async (filter: Record<any, any>) => {
    setUserName(filter.userByName);
    setUserStatus(filter.userByStatus);
  };

  const columns = React.useMemo<TableComponentColumns>(
    () => [
      {
        id: 'img',
        name: 'Imagem',
        selector: (row) => row.img,
        reorder: true,
        grow: 1,
        cell: (row) => (
          <div className="w-9">
            <GhostProfilePicture
              name={row.displayName}
              className=""
              src={row.img}
              alt=""
            />
          </div>
        ),
      },
      {
        id: 'name',
        name: 'Nome',
        selector: (row) => row.name,
        reorder: true,
        grow: 2,
      },
      {
        id: 'displayName',
        name: 'Apelido',
        selector: (row) => row.displayName,
        omit: true,
        reorder: true,
        grow: 2,
      },
      {
        id: 'jobTitle',
        name: 'Cargo',
        selector: (row) => row.jobTitle,
        reorder: true,
      },
      {
        id: 'manager',
        name: 'Perfil',
        selector: (row) => row.manager,
        right: true,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {row.manager?.length > 0 ? 'Manager' : 'Comum'}
          </div>
        ),
      },
      {
        id: 'email',
        name: 'E-mail',
        selector: (row) => row.email,
        reorder: true,
        grow: 2,
      },
      {
        id: 'backupEmail',
        name: 'E-mail secundario',
        selector: (row) => row.backupEmail,
        omit: true,
        reorder: true,
        grow: 2,
      },
      {
        id: 'meritus',
        name: 'meritus',
        selector: (row) => row.meritus,
        reorder: true,
        grow: 1,
        right: true,
      },
      {
        id: 'meritusToCents',
        name: 'Reais',
        selector: (row) => row.meritusToCents,
        right: true,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {<p>{(row.meritusToCents / 100).toFixed(2)}</p>}
          </div>
        ),
      },
      {
        id: 'groupsName',
        name: 'Grupos',
        selector: (row) => row.groupsName.name,
        grow: 3,
        center: true,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {row.groupsName.map((group: any) => (
              <p className="bg-neutral-darker rounded-full px-2">
                {group.name}
              </p>
            ))}
          </div>
        ),
      },
      {
        id: 'meritMoneySchemeId',
        name: 'Modelo distribuição',
        selector: (row) => row.meritMoneySchemeId,
        omit: true,
        reorder: true,
        grow: 3,
      },

      {
        id: 'action',
        name: 'Ação',
        selector: (row) => row.action,
        reorder: true,
        grow: 2,
        cell: (row) => (
          <div>
            {row.isDisabled ? (
              <button
                className="cursor bg-white text-primary-darker rounded-input border border-primary-darker px-2 py-1"
                onClick={() =>
                  selectUserDetails(
                    row as UserModel,
                    UserDetailsTyoe.ENABLE,
                    afterAction
                  )
                }
              >
                Habilitar
              </button>
            ) : (
              <button
                className="cursor bg-white text-primary-darker rounded-input border border-primary-darker px-2 py-1"
                onClick={() =>
                  selectUserDetails(
                    row as UserModel,
                    UserDetailsTyoe.DISABLE,
                    afterAction
                  )
                }
              >
                Desabilitar
              </button>
            )}
              <button
              className="cursor bg-white text-primary-darker rounded-input border border-primary-darker px-2 py-1 ml-3"
              onClick={() => setAndShowUpdateUser(row as UserModel)}
            >
              Editar
            </button>
          </div>
        ),
      },
    ],
    [selectUserDetails, setAndShowUpdateUser]
  );

  return (
    <>
      {/*<p className="text-xl text-black"> Listagem Usuários</p>*/}
      <div className="w-full gap-2 mt-3">
        <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
          <div
            className="flex items-center px-2 py-2 w-full flex cursor-pointer justify-between"
            onClick={handleSetShowFilters}
          >
            <div className="flex">
              <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
              {showFilters ? 'Ocultar Filtros' : 'Mostrar Filtros'}
            </div>
            <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
          </div>
          {showFilters === true && (
            <UsersFilters handleFilters={handleFilters} />
          )}
        </div>
        <div className="mt-2 shadow px-7 pb-7">
          <TableComponent
            title="Lista de Usuários"
            className="w-full"
            data={data}
            columns={columns}
            isLoading={isLoading}
            pagination
            paginationServer
            paginationTotalRows={metadata?.total}
            onChangePage={onChangePage}
            perPage={perPage}
            resetPageToggle={false}
          />
        </div>
        {/*users.map((user) => {
            return (
              <div
                key={user.id}
                className="flex justify-between items-center mt-3 p-3 bg-lighter-20 rounded"
              >
                <div className="flex gap-3 w-3/4 ">
                  <div className="w-12 md:w-16">
                    <GhostProfilePicture
                      src={user.img}
                      alt=""
                      className="shadow"
                      name={user.displayName}
                    />
                  </div>
                  <div className="md:ml-3">
                    <p className="flex-wrap text-xs md:text-base">Apelido: {user.displayName}</p>
                    <p className="flex-wrap text-xs md:text-base">Nome: {user.name}</p>
                    <p className="flex-wrap text-xs md:text-base">Email: {user.email}</p>
                    <p className="flex-wrap text-xs md:text-base">
                      Status: {user.isDisabled ? 'Desabilitado' : 'Habilitado'}
                    </p>
                  </div>
                </div>
                <div>
                  {user?.isDisabled ? (
                    <button
                      className="border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary w-20 md:w-24 text-xs md:text-base"
                      onClick={() =>
                        selectUserDetails(
                          user,
                          UserDetailsTyoe.ENABLE,
                          afterAction
                        )
                      }
                    >
                      Habilitar
                    </button>
                  ) : (
                    <button
                      className="border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary w-20 md:w-24 text-xs md:text-base"
                      onClick={() =>
                        selectUserDetails(
                          user,
                          UserDetailsTyoe.DISABLE,
                          afterAction
                        )
                      }
                    >
                      Desabilitar
                    </button>
                  )}
                </div>
              </div>
            );
          }) */}
      </div>
    </>
  );
};

export default UsersList;
