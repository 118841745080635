import { ErrorMessage, Field, FieldProps } from 'formik';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import React, { useEffect, useState } from 'react';
export type { Option } from 'react-select/src/filters';

export type SelectOptions = OptionsType<OptionTypeBase>;

interface SelectMultiFieldProps {
  name: string;
  options: SelectOptions;
  className?: string;
  placeholder?: string;
  initialValues?: string[];
  disabled?: boolean;
}

const SelectMultiField: React.FC<SelectMultiFieldProps> = (props) => {
  return <Field component={SelectMultiChild} {...props} />;
};

const selectStyle = {
  control: (base: {}) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    indicatorSeparator: { display: 'none' },
  }),
};

interface SelectMultiChildProps extends FieldProps, SelectMultiFieldProps {}

const SelectMultiChild: React.FC<SelectMultiChildProps> = ({
  field,
  form,
  meta,
  options,
  className,
  placeholder,
  initialValues = [],
  disabled,
}) => {
  const [lasRenderOption, setLastRenderOption] = useState(options);

  useEffect(() => {
    if (options.length !== lasRenderOption.length) {
      setLastRenderOption(options);
      const selectedOptions = options.filter((x) =>
        initialValues.includes(x.value)
      );

      form.setFieldValue(field.name, selectedOptions);
    }
  }, [initialValues, field.name, form, options, lasRenderOption]);

  return (
    <div className={`${className} relative`}>
      <Select
        isDisabled={disabled}
        isMulti
        options={options}
        name={field.name}
        className="px-2 border border-lighter-70 rounded-input focus:outline-none"
        styles={selectStyle}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={field.value}
        onChange={(selectedOptions: SelectOptions) => {
          return form.setFieldValue(field.name, selectedOptions);
        }}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
      <p className="text-xs text-red absolute">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default SelectMultiField;
