import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);


export const RequetManagerPurchaseSchema = yup
  .object()
  .shape({
    selectedUser: yup.array().of(yup.string().required()).required(),
    observation: yup.string().max(300),
  })
  .noUnknown(true);
