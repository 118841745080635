// import { getToken } from 'utils/jwt';
import useSWR from 'swr';
import api from './axios';
import { swrFetcher } from './swr';

export interface ProductDisplayModel {
  createdAt: Date;
  name: string;
  desc: string;
  meritus: number;
  instructions: string | undefined;
  categories: string[] | undefined;
  categoriesDetails: Array<{ id: string; name: string; desc: string }>;
  brand: string;
  images: Array<string>;
  id: string;
  type?: 'voucher';
  minMeritus: number;
  minPrice: number;
  variants: string[];
}

export interface ProductVariantModel {
  id: string;
  createdAt: Date;
  name: string;
  meritus: number;
  realPrice: number;
  spreadRealValue: number;
  categories: string[] | undefined;
  categoriesDetails: Array<{ id: string; name: string; desc: string }>;
  type?: 'voucher';
  imgIndex: number;
}

export interface ProductDisplayModelWithVariantData
  extends ProductDisplayModel {
  variantsData: ProductVariantModel[];
}

export interface ListProductDisplayParams {
  page?: number;
  limit?: number;
  meritusMax?: string;
  meritusMin?: string;
  categorie?: string;
  productName?: string;
}

export const getProductDisplay = async (
  productDisplayId: ProductDisplayModel['id']
) => {
  const { data } = await api.request({
    method: 'get',
    url: `productDisplay/byCompany/${productDisplayId}`,
  });
  return data as ProductDisplayModelWithVariantData;
};

export const listProductDisplay = async (
  companyId: string,
  params?: ListProductDisplayParams
) => {
  const { data } = await api.request({
    method: 'get',
    url: `productDisplay/listByCompany/${companyId}`,
    params,
  });
  return data as {
    data: ProductDisplayModel[];
    metadata: { total: number };
  };
};


export const useSWRListCompanyDisplayProducts = (
  companyId: string | undefined,
  params?: { offset?: number; limit?: number },
  requestKey: number = 0,
) => {
  const { data, error } = useSWR<any>(
    companyId ? [`productDisplay/listByCompany/${companyId}`, { params }, requestKey] : null,
    swrFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount:true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );
  
  return {
    data: data?.data.data,
    metadata: data?.data.metadata,
    isLoading: !error && !data,
    isError: error,
  };
};