import api from './axios';
import useSWR from 'swr';
import { CompanyModel } from './company';
import { UserModel } from './users';
import { swrFetcher } from './swr';

export type MissionModel = {
  id: string;
  company: CompanyModel['id'];
  name: string;
  description: string;
  meritus: number | string;
  status: 'done' | 'running';
  approveType: 'simple' | 'proof';
  groups: Array<string> | Array<any>;
  startsAt?: Date | null;
  endsAt?: Date | null;
  acceptedBy: Array<UserModel['id']>;
  companyValues: string[];
  doneBy: Array<{
    userId: UserModel['id'];
    prove: string;
  }>;
  createdAt: Date;
  walletId?: string;
};

export type MissionModelCreate = {
  company?: CompanyModel['id'];
  name: MissionModel['name'];
  description: MissionModel['description'];
  meritus: MissionModel['meritus'];
  approveType: MissionModel['approveType'];
  groups: MissionModel['groups'];
  startsAt?: MissionModel['startsAt'];
  endsAt?: MissionModel['endsAt'];
  walletId?: string | null;
};

export type MissionModelUpdate = Omit<MissionModelCreate, 'company'>

export const useSWRListCompanyMission = (
  companyId: CompanyModel['id'] | undefined,
  params?: { offset?: number; limit?: number },
  requestKey: number = 0,
) => {
  const { data, error } = useSWR<any>(
    companyId ? [`mission/listByCompany/${companyId}`, { params }, requestKey] : null,
    swrFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount:true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );
  
  return {
    data: data?.data.data,
    metadata: data?.data.metadata,
    isLoading: !error && !data,
    isError: error,
  };
};

export const listCompanyMission = async (
  companyId: CompanyModel['id'],
  params?: { offset?: number; limit?: number }
) => {
  const { data } = await api.request({
    method: 'get',
    url: `mission/listByCompany/${companyId}`,
    params,
  });
  return data;
};

export const listUserGroupsMission = async () => {
  const { data } = await api.request({
    method: 'get',
    url: `mission/listByUserGroups`,
  });
  return data;
};

export const subscribeToMission = async (missionId: MissionModel['id']) => {
  const { data } = await api.request({
    method: 'POST',
    url: `mission/subscribe/${missionId}`,
  });
  return data;
};

export const finishMission = async (
  missionId: MissionModel['id'],
  file: File | undefined
) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }

  const { data } = await api.request({
    method: 'POST',
    url: `mission/finish/${missionId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
  return data;
};

export const createMission = async (payload: MissionModelCreate) => {
  const { data } = await api.request({
    url: '/mission',
    method: 'POST',
    data: payload,
  });
  return data;
};

export const updateMission = async (missionId: string, payload: MissionModelUpdate) => {
  const { data } = await api.request({
    url: `/mission/${missionId}`,
    method: 'PUT',
    data: payload,
  });
  return data;
};

export const enableMission = async (missionId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/mission/enable/${missionId}`,
  });
  return data;
};

export const disableMission = async (missionId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/mission/disable/${missionId}`,
  });
  return data;
};