import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react';

interface InputTextAreaProps {
  placeholder?: string;
  name: string;
  className?: string;
}
const InputTextArea: React.FC<InputTextAreaProps> = (props) => {
  return <Field component={InputTextAreaChild} {...props} />;
};

interface InputTextAreaChildProps extends FieldProps, InputTextAreaProps {}

const InputTextAreaChild: React.FC<InputTextAreaChildProps> = ({
  className,
  placeholder,
  name,
  field,
  form,
  meta,
}) => {
  return (
    <div className={`${className} relative`}>
      <textarea
        name={name}
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);
        }}
        className={`w-full h-full border rounded-input p-2 resize-none ${
          form.touched[field.name] && form.errors[field.name]
            ? 'border-red'
            : 'border-lighter-70'
        }`}
        placeholder={placeholder}
      />
      <p className="text-xs text-red absolute font-bold">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default InputTextArea;
