import { i18n } from 'utils/i18n/index';
import { Formik, FormikHelpers } from 'formik';
import InputText from 'Components/Formik/InputText';
// import SearchCard from 'Components/SearchCard';
import React from 'react';

// import { ReactComponent as SearchIcon } from '../../assets/vectors/search.svg';

interface StoreFiltersProps {
  handleFilters: (filters: Record<any, any>) => void;
  isReRendering?: boolean;
}

const StoreFilters: React.FC<StoreFiltersProps> = ({
  handleFilters,
  isReRendering,
}) => {
  // const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const initialValues = {
    meritusMax: '',
    meritusMin: '',
    categorie: null,
    productName: '',
    // productSupplier: SupplierModel['id'] | undefined;
  };

  const handleSubmit = async (
    values: Partial<typeof initialValues>,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);

    // remove empty string from request
    const keys = Object.keys(values) as Array<keyof typeof values>;
    keys.forEach((key) =>
      values[key] === '' ? (values[key] = undefined) : {}
    );

    await handleFilters(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm, resetForm }) => (
        <div className="w-full">
          {/* <div className="w-full flex lg:gap-8 gap-4 justify-start items-start flex-wrap py-3">
            <div
              className={`flex flex-col py-1 px-1 ${
                isReRendering ? 'opacity-30' : ''
              }`}
            >
              <p className="text-sm mb-2">{i18n.t('productName')}:</p>
              <InputText
                name="productName"
                placeholder={'nome'}
                className="w-40"
              />
            </div>
            <div
              className={`flex flex-col py-1 px-1 ${
                isReRendering ? 'opacity-30' : ''
              }`}
            >
              <p className="text-sm mb-2">{i18n.t('minimumPointValue')}:</p>
              <InputText
                name="meritusMin"
                className="w-40 rounded-input"
                placeholder={i18n.t('minimumPoints')}
              />
            </div>
            <div
              className={`flex flex-col py-1 px-1 ${
                isReRendering ? 'opacity-30' : ''
              }`}
            >
              <p className="text-sm mb-2">{i18n.t('maximumPointValue')}:</p>
              <InputText
                name="meritusMax"
                className="w-40 rounded-input"
                placeholder={i18n.t('maximumValue')}
              />
            </div>

            <div className="w-full flex justify-end mt-2 gap-7">
              <button
                className="text-primary mt-2"
                onClick={() => {
                  resetForm({
                    values: {
                      ...initialValues,
                    },
                  });
                  submitForm();
                }}
              >
                {i18n.t('clean')}
              </button>
              <button
                className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                type="submit"
                onClick={submitForm}
                disabled={isReRendering}
              >
                {i18n.t('search')}
              </button>
            </div>
          </div> */}
        </div>
      )}
    </Formik>
  );
};

export default StoreFilters;
