import api from './axios';

export const requestPasswordRecoveryApi = async (data: { email: string }) => {
  const response = await api.request({
    method: 'post',
    url: 'passwordRecovery',
    data,
  });
  return response.data;
};

export const finishPasswordRecoveryApi = async (
  passwordRecoveryId: string,
  data: { password: string }
) => {
  const response = await api.request({
    method: 'post',
    url: `passwordRecovery/${passwordRecoveryId}`,
    data,
  });
  return response.data;
};

export const isValidPasswordRecoveryApi = async (
  passwordRecoveryId: string
): Promise<{ status: string }> => {
  const response = await api.request({
    method: 'get',
    url: `passwordRecovery/status/${passwordRecoveryId}`,
  });
  return response.data;
};
