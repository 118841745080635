import React from 'react';
import { Formik, Form, Field } from 'formik';
import SelectField, { SelectOptions } from 'Components/Formik/SelectField';
import { useEffect } from 'react';
import { useState } from 'react';
import { getCompany } from 'utils/api/company';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import SelectMultiField from './Formik/SelectMultiField';
import { toast } from 'react-toastify';
import {
  disableUser,
  disableUserWithAccess,
  enableUser,
  UserModel,
} from 'utils/api/users';
import { SingleSpreadedSelectOptions } from './Formik/SingleSpreadedSelect';
import { listMeritMoneySchemeByCompany } from 'utils/api/meritMoneyScheme';
import { disabledUserSchema } from 'utils/validators/disabledUser';

interface UserDetailsProps {
  selectedUser: UserModel | undefined;
  cancel: (refresh?: boolean, newUserDetails?: UserModel) => void;
  type: UserDetailsTyoe;
  then: (selectedUser: UserModel) => void;
}

export enum UserDetailsTyoe {
  ENABLE = 'enable',
  DISABLE = 'disable',
  DETAIL = 'detail',
}

const UserDetails: React.FC<UserDetailsProps> = ({
  cancel,
  selectedUser,
  type,
  then,
}) => {
  const { user } = useContext(UserContext);
  const [meritMoneySchemeOptions, setMeritMoneySchemeOptions] =
    useState<SelectOptions>([]);
  const [values, setValues] = useState<SingleSpreadedSelectOptions>([]);
  const [groupsOptions, setGroupsOptions] = useState<SelectOptions>([]);
  const [options, setOptions] = useState({
    extraInfo: true,
    showConfirm: false,
    text: 'Detalhes',
    buttonText: 'Detalhes',
    disableInput: true,
    showDisableWithAccess: true,
  });

  useEffect(() => {
    if (user?.companyId === undefined) return;

    getCompany(user?.companyId || '').then((data) => {
      const { values, groups } = data;

      const processed = values.map((value) => ({
        label: value.name,
        value: value.name,
      }));
      setValues(processed);

      if (groups === undefined) return setGroupsOptions([]);
      const options = groups.map((group) => ({
        label: group.name,
        value: group._id,
      }));
      setGroupsOptions(options);
    });

    listMeritMoneySchemeByCompany(user.companyId).then(
      (meritMoneySchemeList) => {
        const options = meritMoneySchemeList.map((meritMoneyScheme) => ({
          label: meritMoneyScheme.name,
          value: meritMoneyScheme.id,
        }));
        setMeritMoneySchemeOptions(options);
      }
    );
  }, [user]);

  useEffect(() => {
    switch (type) {
      case UserDetailsTyoe.ENABLE:
        setOptions({
          extraInfo: false,
          showConfirm: true,
          text: 'Deseja mesmo habilitar o usuário?',
          buttonText: 'Habilitar',
          disableInput: true,
          showDisableWithAccess: false,
        });
        break;
      case UserDetailsTyoe.DISABLE:
        setOptions({
          extraInfo: false,
          showConfirm: true,
          text: 'Deseja mesmo desabilitar o usuário?',
          buttonText: 'Desabilitar',
          disableInput: true,
          showDisableWithAccess: true,
        });
        break;

      default:
        break;
    }
  }, [type]);

  if (selectedUser === undefined) {
    toast.error('Usuário inválido');
    cancel();
    return <></>;
  }

  const initialValues = {
    name: selectedUser.name,
    displayName: selectedUser.displayName,
    bio: selectedUser.bio,
    backupEmail: selectedUser.backupEmail,
    selectedValues: selectedUser.selectedValues,
    meritus: selectedUser.meritus,
    meritMoney: selectedUser.meritMoney,
    birthday: selectedUser.birthday,
    email: selectedUser.email,
    img: selectedUser.img,
    registration: selectedUser.registration,
    groups: [] as SelectOptions,
    meritMoneySchemeId: selectedUser.meritMoneySchemeId,
    withAccess: false,
    newEmail: selectedUser.backupEmail,
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    switch (type) {
      case UserDetailsTyoe.DISABLE:
        const promise = values.withAccess
          ? disableUserWithAccess(selectedUser.id, {
              newEmail: values.newEmail,
            })
          : disableUser(selectedUser.id);
        toast
          .promise(promise, {
            error: {
              render: (res: any) => {
                const data = res?.data?.response?.data
                return data || 'Não foi possivel desativado o usuário, tente mais tarde'
              }
            },
            pending: 'Desativando usuário',
            success: {
              render: () => {
                then(selectedUser);
                cancel();
                return 'Usuário desativado';
              },
            },
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        break;
      case UserDetailsTyoe.ENABLE:
        toast
          .promise(enableUser(selectedUser.id), {
            error: 'Nào foi possivel Ativar o usuário, tente mais tarde',
            pending: 'Ativando usuário',
            success: {
              render: () => {
                then(selectedUser);
                cancel();
                return 'Usuário ativado';
              },
            },
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-white p-8 rounded md:w-5/12">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={disabledUserSchema}
      >
        {({ isSubmitting, values }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">{options.text}</p>
            <p className="mt-8">Nome:</p>
            <InputText
              name="name"
              placeholder="Digite o nome do usuário"
              disabled
            />
            <p className="mt-8">E-mail:</p>
            <InputText
              name="email"
              placeholder="Digite o email do usuário"
              disabled
            />
            {options.showDisableWithAccess && (
              <label className="">
                <Field
                  type="checkbox"
                  name="withAccess"
                  className="mr-2 mt-2"
                />
                Com acesso à loja
              </label>
            )}
            {values.withAccess && (
              <>
                <p className="mt-8">E-mail pessoal:</p>
                <InputText
                  name="newEmail"
                  placeholder="Digite o email do usuário"
                />
              </>
            )}
            {options.extraInfo && (
              <>
                <p className="mt-8">Esquema de merit money:</p>
                <SelectField
                  name="meritMoneySchemeId"
                  placeholder="Selecione um esquema de merit money"
                  options={meritMoneySchemeOptions}
                  className="mt-2"
                  disabled={options.disableInput}
                />
                <p className="mt-8">Grupos:</p>
                <SelectMultiField
                  name="groups"
                  options={groupsOptions}
                  className="mt-2"
                  initialValues={selectedUser.groups}
                  disabled={options.disableInput}
                />
              </>
            )}
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                {options.showConfirm && (
                  <button
                    type="submit"
                    className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                    disabled={isSubmitting}
                  >
                    {options.buttonText}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserDetails;
