import React, { useState, useContext, useCallback } from 'react';
import Navbar from 'Components/Navbar';
import { MissionModel, useSWRListCompanyMission } from 'utils/api/mission';
import { UserModel } from 'utils/api/users';
import { UserContext } from 'Components/Context/UserContext';
import { UserDetailsTyoe } from 'Components/UserDetails';
import TableComponent, {
  TableComponentColumns,
} from 'Components/TableComponent';
import AddMission from 'Components/Popup/AddMission';
import PopUp from 'Components/PopUp';
import UpdateMission from 'Components/Popup/UpdateMission';
import EnableMission from 'Components/Popup/EnableMission';
import DisableMission from 'Components/Popup/DisableMission';
interface MissionListProps {
  selectMissionDetails: (
    user: UserModel,
    type?: UserDetailsTyoe,
    then?: (selectedMission: MissionModel) => {}
  ) => void;
}

const MissionTable: React.FC<MissionListProps> = () => {
  const perPage = 15;

  const { user } = useContext(UserContext);

  const [showAddMission, setShowAddMission] = useState(false);
  const [showUpdateMission, setShowUpdateMission] = useState(false);
  const [showEnableMission, setShowEnableMission] = useState(false);
  const [showDisableMission, setShowDisableMission] = useState(false);
  const [selectedMission, setSelectedMission] = useState({} as MissionModel);

  const [resetPageToggle, setResetPageToggle] = useState(false);

  const [renderKey, setRenderKey] = useState(0);
  const [page, setPage] = useState(1);
  const { data, metadata, isError, isLoading } = useSWRListCompanyMission(
    user?.companyId,
    {
      offset: (page - 1) * perPage || 0,
      limit: perPage,
    },
    renderKey
  );

  const setAndShowUpdateMission = (mission: MissionModel) => {
    setSelectedMission(mission);
    setShowUpdateMission(true);
  };

  const setAndShowEnableMission = (mission: MissionModel) => {
    setSelectedMission(mission);
    setShowEnableMission(true);
  };

  const setAndShowDisableMission = (mission: MissionModel) => {
    setSelectedMission(mission);
    setShowDisableMission(true);
  };

  const columns = React.useMemo<TableComponentColumns>(
    () => [
      {
        id: 'name',
        name: 'Nome',
        selector: (row) => row.name,
        reorder: true,
        grow: 2,
      },
      {
        id: 'description',
        name: 'Descrição',
        selector: (row) => row.description,
        reorder: true,
        omit: true,
        grow: 1,
      },
      {
        id: 'meritus',
        name: 'Meritus',
        selector: (row) => row.meritus,
        reorder: true,
        right: true,
      },
      {
        id: 'isDisabled',
        name: 'Status',
        selector: (row) => row.isDisabled,
        reorder: true,
        omit: false,
        cell: (row) => <p className='whitespace-nowrap'>{row.isDisabled ? 'Desabilitado' : 'Habilitado'}</p>
      },
      {
        id: 'approveType',
        name: 'Método',
        selector: (row) => row.approveType,
        reorder: true,
        omit: true,
      },
      {
        id: 'doneBy',
        name: 'Feita por',
        selector: (row) => row.doneBy,
        right: true,
        reorder: true,
        omit: true,
        cell: (row) => <p className="">{row.doneBy.length}</p>,
      },
      {
        id: 'acceptedBy',
        name: 'Aceita por',
        selector: (row) => row.acceptedBy,
        reorder: true,
        right: true,
        omit: true,
        cell: (row) => <p className="">{row.doneBy.length}</p>,
      },
      {
        id: 'createdAt',
        name: 'Criada em',
        selector: (row) => row.createdAt,
        reorder: true,
        omit: false,
        cell: (row) => (
          <p className="whitespace-nowrap">
            {new Date(row.createdAt).toLocaleDateString('pt-br')}
          </p>
        ),
      },
      {
        id: 'startsAt',
        name: 'Inicia em',
        selector: (row) => row.startsAt,
        reorder: true,
        omit: false,
        cell: (row) => (
          <p className="whitesapce-nowrap">
            {row.startsAt && new Date(row.startsAt).toLocaleDateString()}
          </p>
        ),
      },
      {
        id: 'endsAt',
        name: 'Termina em',
        selector: (row) => row.endsAt,
        reorder: true,
        omit: true,
        cell: (row) => (
          <p className="whitespace-nowrap">
            {row.endsAt && new Date(row.endsAt).toLocaleDateString()}
          </p>
        ),
      },
      {
        id: 'groups',
        name: 'Grupos',
        selector: (row) => row.groups,
        grow: 3,
        reorder: true,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {row.groups
              ? row.groups.map((group: any) => (
                  <p
                    key={group.id}
                    className="bg-neutral-darker rounded-full px-2"
                  >
                    {group.name}
                  </p>
                ))
              : undefined}
          </div>
        ),
      },
      {
        id: 'companyValues',
        name: 'Valores',
        selector: (row) => row.companyValues,
        grow: 3,
        reorder: true,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {row.companyValues
              ? row.companyValues.map((valores: any) => (
                  <p
                    key={valores}
                    className="bg-neutral-darker rounded-full px-2"
                  >
                    {valores}
                  </p>
                ))
              : undefined}
          </div>
        ),
      },
      {
        id: 'wallet',
        name: 'Carteira',
        selector: (row) => row.wallet,
        reorder: true,
        cell: (row) => <p className="whitespace-nowrap">{row.wallet?.name || 'Geral'}</p>,
      },
      {
        id: 'actions',
        name: 'Ação',
        selector: (row) => row.actions,
        reorder: true,
        grow: 3,
        cell: (row) => (
          <div>
            <button
              className="whitespace-nowrap cursor bg-white text-primary-darker rounded-input border border-primary-darker px-1 my-1 md:px-2 py-1 mr-3"
              onClick={() => setAndShowUpdateMission(row as MissionModel)}
            >
              Editar
            </button>
            {row.isDisabled ? (
              <button
                className="whitespace-nowrap cursor bg-white text-primary-darker rounded-input border border-primary-darker px-1 my-1 md:px-2 py-1 mr-3"
                onClick={() => setAndShowEnableMission(row as MissionModel)}
              >
                Habilitar
              </button>
            ) : (
              <button
                className="whitespace-nowrap cursor bg-white text-primary-darker rounded-input border border-primary-darker px-1 my-1 md:px-2 py-1"
                onClick={() => setAndShowDisableMission(row as MissionModel)}
              >
                Desabilitar
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const handleCancelPopUp = (refresh = false) => {
    if (refresh) {
      setRenderKey(Math.random());
      setResetPageToggle(!resetPageToggle);
    }
    setShowAddMission(false);
    setShowUpdateMission(false);
    setShowEnableMission(false);
    setShowDisableMission(false);
  };

  const onChangePage = useCallback(async (page: number) => {
    setPage(page);
  }, []);

  return (
    <>
      <PopUp show={showAddMission}>
        <AddMission cancel={handleCancelPopUp} />
      </PopUp>
      <PopUp show={showUpdateMission}>
        <UpdateMission cancel={handleCancelPopUp} mission={selectedMission} />
      </PopUp>
      <PopUp show={showEnableMission}>
        <EnableMission cancel={handleCancelPopUp} mission={selectedMission} />
      </PopUp>
      <PopUp show={showDisableMission}>
        <DisableMission cancel={handleCancelPopUp} mission={selectedMission} />
      </PopUp>
      <Navbar />
      <div className="px-10 mt-7">
        <p className="text-xs">
          <span className="text-primary">Missões</span>
        </p>
        <div className="flex items-center justify-between">
          <p className="text-3xl mt-4 text-black">Missões</p>
          <button
            onClick={() => setShowAddMission(true)}
            className="border border-primary-darker p-2 rounded-input text-primary-darker hover:border-primary hover:text-primary"
          >
            Adicionar missão
          </button>
        </div>
        <div className="mt-16 shadow px-7 pb-7">
          <TableComponent
            className="w-full"
            data={data}
            columns={columns}
            isLoading={isLoading}
            pagination
            paginationServer
            paginationTotalRows={metadata?.total}
            onChangePage={onChangePage}
            perPage={perPage}
            resetPageToggle={resetPageToggle}
          />
        </div>
      </div>
    </>
  );
};

export default MissionTable;
