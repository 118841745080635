import { UserContext } from 'Components/Context/UserContext';
import SelectField from 'Components/Formik/SelectField';
import Totalizer from 'Components/Totalizer';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  CompanyValuesTotalizer,
  getCompanyValuesTotalizer,
} from 'utils/api/totalizers';
import { getDatesFromPeriod, getMonth } from './GetDatas';
import { listGroupByCompany } from '../../utils/api/group';
import SelectMultiField, { Option } from 'Components/Formik/SelectMultiField';

import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';

interface CompanyValuesStatisticsProps {}

const CompanyValuesStatistics: React.FC<CompanyValuesStatisticsProps> = () => {
  const { user } = useContext(UserContext);
  const [companyValuesTotalizer, setCompanyValuesTotalizer] = useState<
    CompanyValuesTotalizer | undefined | null
  >();
  const [groupsOptions, setGroupsOptions] = useState<Option[]>([]);
  const [totalValues, setTotalValues] = useState(0);
  const [showFilters, setShowFilters] = useState(false);


  const today = new Date();
  const month = today.getMonth() + 1;

  const orderCompanyValues = (data: CompanyValuesTotalizer) => {
    return data.sort((a, b) => {
      const firstFilter = b.count - a.count;
      if (firstFilter !== 0) return firstFilter; // values are diferent and its not necessary to check second filter
      return a.companyValue < b.companyValue ? -1 : 1;
    });
  };

  useEffect(() => {
    const aux = companyValuesTotalizer?.reduce((prev, currentValue) => {
      return prev + (currentValue.count || 0);
    }, 0);
    setTotalValues(aux || 0);
  }, [companyValuesTotalizer]);

  useEffect(() => {
    if (!user?.companyId) return console.log('deu ruim');
    const dates = getDatesFromPeriod('month');
    getCompanyValuesTotalizer(user.companyId, { dates, receiverGroups: [], senderGroups: [] })
      .then(orderCompanyValues)
      .then(setCompanyValuesTotalizer);

    listGroupByCompany(user.companyId).then((resp) => {
      const options = resp.data.map((group) => ({
        value: group.id,
        label: group.name,
        data: group,
      }));
      setGroupsOptions(options);
    });
  }, [user]);

  const initialValues = {
    period: 'month',
    receiverGroups: [] as Option[],
    senderGroups: [] as Option[],
  };

  const generatePercentageString = (value = 0, total: number) => {
    if (total === 0) return 0;
    const percentage = (100 * value) / total;
    return percentage.toFixed(2);
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);

    if (!user?.companyId) return console.log('deu ruim');
    const dates = getDatesFromPeriod(values.period);
    const receiverGroups = values.receiverGroups.map((group) => group.value);
    const senderGroups = values.senderGroups.map((group) => group.value);

    try {
      await getCompanyValuesTotalizer(user.companyId, { ...values, dates, senderGroups, receiverGroups })
        .then(orderCompanyValues)
        .then(setCompanyValuesTotalizer);
    } catch (error) {
      toast.error('Algo deu errado');
    }
    actions.setSubmitting(false);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting, resetForm }) => (
          <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
            <div
              className=" items-center px-2 py-2 w-full flex cursor-pointer justify-between"
              onClick={() => setShowFilters(!showFilters)}
            >
              <div className="flex">
                <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
                {showFilters ? 'Ocultar Filtros' : 'Mostrar Filtros'}
              </div>
              <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
            {showFilters === true && (
              <div className='w-full flex lg:gap-8 gap-4 justify-start items-start flex-wrap py-3'>
                <div>
                  <label className="text-gray-700 font-bold">Período</label>
                  <SelectField
                    name="period"
                    options={[
                      { value: 'month', label: 'Esse mês' },
                      { value: 'last7Days', label: 'Últimos 7 dias' },
                      { value: 'last30Days', label: 'Últimos 30 dias' },
                      { value: 'lastMonth', label: getMonth(month - 1) },
                      { value: 'twoMonthsAgo', label: getMonth(month - 2) },
                      { value: 'threeMonthsAgo', label: getMonth(month - 3) },
                      { value: 'fourMonthsAgo', label: getMonth(month - 4) },
                      { value: 'fiveMonthsAgo', label: getMonth(month - 5) },
                      { value: 'sixMonthsAgo', label: getMonth(month - 6) },
                    ]}
                    className="my-1 w-44"
                    disabled={isSubmitting}
                  /> 
                </div>
                <div className="">
                  <label className="text-gray-700 font-bold">Grupos</label>
                  <SelectMultiField
                    name="receiverGroups"
                    options={groupsOptions}
                    className="my-1 min-w-80 bg-white"
                    placeholder="Grupos"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-full flex justify-end mt-2 gap-7">
                  <button
                    className="text-primary mt-2"
                    onClick={() => {
                      resetForm({
                      values: {
                        ...initialValues,
                      },
                    });
                    submitForm();
                  }
                  }
                  >
                    Limpar
                  </button>
                  <button
                    className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                    type="submit"
                    onClick={submitForm}
                  >
                    Procurar
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
      <main className="flex mt-5 items-center flex-wrap gap-5">
        {companyValuesTotalizer?.map((value) => (
          <Totalizer>
            {console.log(value)}
            <p>{value.companyValue} </p>
            <p className="font-bold text-2xl">
              {generatePercentageString(value.count, totalValues)}%
            </p>
            <div>{`${value.count} reconhecimento(s)`}</div>
          </Totalizer>
        ))}
      </main>
    </div>
  );
};

export default CompanyValuesStatistics;
