interface TagProps {
  alternative?: boolean;
  colorless?: boolean;
  inverted?: boolean;
  className?: string;
  theme?: string | null;
}

const Tag: React.FC<TagProps> = ({
  children,
  alternative,
  colorless,
  inverted,
  className,
  theme,
}) => {
  const themeObject = {
    colorless: 'bg-neutral-darker text-darker-50',
    alternative: 'bg-secondary text-black',
    inverted: 'bg-white text-black',
    default: 'bg-primary-darker text-white',
    custom: `bg-${theme} text-white`,
  };
  const themeSelector = colorless
    ? 'colorless'
    : alternative
    ? 'alternative'
    : inverted
    ? 'inverted'
    : theme
    ? 'custom'
    : 'default';

  return (
    <div
      className={`${className}
      ${themeObject[themeSelector]} rounded-full text-xs px-3 py-1 text-center flex items-center justify-center whitespace-nowrap`}
    >
      {children}
    </div>
  );
};

export default Tag;
