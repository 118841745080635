import React from 'react';
import { Formik, Form } from 'formik';

import { ReactComponent as LogoIcon } from 'assets/vectors/logo.svg';
import { ReactComponent as GiftIcon } from 'assets/vectors/gift.svg';
import img from 'assets/images/login.png';

import { authenticateSchema } from 'utils/validators/authentication';
import { userAuthenticate } from 'utils/api/users';
import { useState } from 'react';

import AutoMargin from 'Components/AutoMargin';
import PopUp from 'Components/PopUp';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import InputText from 'Components/Formik/InputText';
import RequestPasswordRecovery from 'Components/RequestPasswordRecovery';
import Loading from 'Components/Animations/Loading';
import { loginCookie } from 'utils/jwt';

export interface LoginProps {}
const Login: React.FC<LoginProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const { login, user } = useContext(UserContext);

  return (
    <div className="flex justify-between items-stretch min-h-screen">
      <PopUp show={showPasswordRecovery}>
        <RequestPasswordRecovery
          cancel={() => setShowPasswordRecovery(false)}
        />
      </PopUp>

      <div className="flex flex-col justify-around p-16 min-h-full bg-primary-darker w-7/12 rounded-r-xl">
        <p className="font-normal text-7xl text-white">
          Impulsionando
          <br />o valor <br />
          das conexões
        </p>
        <div className="flex justify-between">
          <div className="rounded-full w-1/4">
            <img src={img} alt="" className="rounded-full w-full h-auto" />
          </div>
          <div className=" relative transform translate-y-1/2 w-1/4">
            <div className="absolute transform -translate-y-1/2 rounded-full bg-secondary w-full h-full" />
            <div className="relative flex justify-center items-center w-full pb-full">
              <GiftIcon className="absolute stroke-current text-white w-1/2" />
              <div className="absolute transform -translate-x-1/2 border-r-0 border-solid border-white border w-screen h-full border-opacity-30" />
              <div className="absolute transform translate--1/2 -translate-y-1/2 border-b-0 border-solid border-white border w-full h-screen border-opacity-30" />
            </div>
          </div>
        </div>
      </div>

      <main className="flex justify-center items-center flex-grow">
        <AutoMargin className="w-96">
          <div className="flex gap-3 items-center font-bold text-4xl text-black mb-28">
            <LogoIcon />
            meritu<span className='text-base text-darker-50 font-normal'>manager</span>
          </div>
          <div className="font-medium text-xl text-black">Acesse sua conta</div>
          <div className="mb-16">
            Entre na plataforma e descubra um mundo mais colaborativo :)
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={authenticateSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              userAuthenticate(values)
                .then(({ token, exp, domain }) => {
                  loginCookie(token, exp, domain);
                  login();
                  // login(token); used when it was local storage based
                  actions.setSubmitting(false);
                  // const userId = getTokenPayload()?.sub;
                  // if (!userId) {
                  //   throw new Error();
                  // } else {
                  //   getUser(userId).then(setUser);
                  //   history.push('/colaborador/reconhecimento');
                  // }
                })
                .catch((error) => {
                  actions.setSubmitting(false);
                  error.response?.status === 401
                    ? setErrorMessage('Email ou senha invalido')
                    : setErrorMessage('Algo deu errado');
                });
            }}
          >
            {({ isSubmitting }) =>
              user === undefined ? (
                <Loading />
              ) : (
                <Form className="flex flex-col">
                  <p className="text-xs text-red absolute font-bold">
                    {errorMessage}
                  </p>
                  <label className="flex flex-col text-xs w-full mt-6">
                    E-mail:
                    <InputText name="email" placeholder="Digite seu email" />
                  </label>
                  <label className="flex flex-col text-xs w-full mt-6">
                    Password
                    <InputText
                      name="password"
                      password
                      className="mt-2"
                      placeholder="Digite sua senha"
                    />
                  </label>
                  <div className="flex justify-end mt-2">
                    {/* <label className="">
                    <input type="checkbox" name="" id="" className="mr-2" />
                    Manter conectado
                  </label> */}
                    <button
                      onClick={() => setShowPasswordRecovery(true)}
                      type="button"
                      className="text-primary-darker mr-2 text-sm hover:text-primary"
                    >
                      Esqueci a senha
                    </button>
                  </div>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="py-2 bg-primary-darker text-white box-border rounded-full mt-10 disabled:opacity-30"
                  >
                    Entrar
                  </button>
                </Form>
              )
            }
          </Formik>
        </AutoMargin>
      </main>
    </div>
  );
};

export default Login;
