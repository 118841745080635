import { UserContext } from 'Components/Context/UserContext';
import SelectField from 'Components/Formik/SelectField';
import SelectMultiField, { Option } from 'Components/Formik/SelectMultiField';
import Totalizer from 'Components/Totalizer';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActivityTotalizer, getActivityTotalizer } from 'utils/api/totalizers';
import { listGroupByCompany } from 'utils/api/group';
import { getDatesFromPeriod, getMonth } from './GetDatas';

import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';

interface ActivityStatisticsProps {}

const ActivityStatistics: React.FC<ActivityStatisticsProps> = () => {
  const { user } = useContext(UserContext);
  // activityTotalizer state
  const [activityTotalizer, setActivityTotalizer] = useState<
    ActivityTotalizer | undefined | null
  >();
  const [groupsOptions, setGroupsOptions] = useState<Option[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  const today = new Date();
  const month = today.getMonth() + 1;


  useEffect(() => {
    if (!user?.companyId) return console.log('deu ruim');
    const dates = getDatesFromPeriod('month');
    getActivityTotalizer(user.companyId, { dates, groups: [] }).then(setActivityTotalizer);
    listGroupByCompany(user.companyId).then((resp) => {
      const options = resp.data.map((group) => ({
        value: group.id,
        label: group.name,
        data: group,
      }));
      setGroupsOptions(options);
    });
  }, [user]);

  const initialValues = {
    period: 'month',
    groups: [] as Option[],
  };

  const generatePercentageString = (value = 0, total?: number) => {
    if (total === 0 || total === undefined) return 0;
    const percentage = (100 * value) / total;
    return percentage.toFixed(2);
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);
    
    if (!user?.companyId) return console.log('deu ruim');
    const dates = getDatesFromPeriod(values.period);
    const groups = values.groups.map((group) => group.value);

    try {
      await getActivityTotalizer(user.companyId, { ...values, dates, groups }).then(
        setActivityTotalizer
      );
    } catch (error) {
      toast.error('Algo deu errado');
    }
    actions.setSubmitting(false);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting, resetForm }) => (
          <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
            <div
              className=" items-center px-2 py-2 w-full flex cursor-pointer justify-between"
              onClick={() => setShowFilters(!showFilters)}
            >
              <div className="flex">
                <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
                {showFilters ? 'Ocultar Filtros' : 'Mostrar Filtros'}
              </div>
              <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
            {showFilters === true && (
              <div className="w-full flex lg:gap-8 gap-4 justify-start items-start flex-wrap py-3">
                <div>
                  <label className="text-gray-700 font-bold">Período</label>
                  <SelectField
                    name="period"
                    options={[
                      { value: 'month', label: 'Esse mês' },
                      { value: 'last7Days', label: 'Últimos 7 dias' },
                      { value: 'last30Days', label: 'Últimos 30 dias' },
                      { value: 'lastMonth', label: getMonth(month - 1) },
                      { value: 'twoMonthsAgo', label: getMonth(month - 2) },
                      { value: 'threeMonthsAgo', label: getMonth(month - 3) },
                      { value: 'fourMonthsAgo', label: getMonth(month - 4) },
                      { value: 'fiveMonthsAgo', label: getMonth(month - 5) },
                      { value: 'sixMonthsAgo', label: getMonth(month - 6) },
                    ]}
                    className="my-1 w-44"
                    // onChange={submitForm}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="">
                  <label className="text-gray-700 font-bold">Grupos</label>
                  <SelectMultiField
                    name="groups"
                    options={groupsOptions}
                    className="my-1 min-w-80 bg-white"
                    placeholder="Grupos"
                    // onChange={submitForm}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-full flex justify-end mt-2 gap-7">
                  <button
                    className="text-primary mt-2"
                    onClick={() => {
                      resetForm({
                        values: {
                          ...initialValues,
                        },
                      });
                      submitForm();
                    }}
                  >
                    Limpar
                  </button>
                  <button
                    className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                    type="submit"
                    onClick={submitForm}
                  >
                    Procurar
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
      <main className="flex items-center flex-wrap mt-5 gap-5">
        <Totalizer>
          <p>Usuários totais </p>
          <p className="font-bold text-2xl">{activityTotalizer?.totalUser}</p>
          <p>
            {activityTotalizer?.userDisabledOnPeriod
              ? `${activityTotalizer?.userDisabledOnPeriod} desabilitados no período`
              : ''}
          </p>
        </Totalizer>
        <Totalizer>
          <p>Usuários ativos </p>
          <p className="font-bold text-2xl">
            {generatePercentageString(
              activityTotalizer?.activeUsers,
              activityTotalizer?.totalUser
            )}
            %
          </p>
          <p>{activityTotalizer?.activeUsers} usuários</p>
        </Totalizer>
        <Totalizer>
          <p>Total reconhecimentos <br />dados </p>
          <p className="font-bold text-2xl">
            {activityTotalizer?.recognitionsMade}
          </p>
          <p> </p>
        </Totalizer>
        <Totalizer>
          <p>Total reconhecimentos recebidos </p>
          <p className="font-bold text-2xl">
            {activityTotalizer?.recognitionsReceived}
          </p>
          <p> </p>
        </Totalizer>
        <Totalizer>
          <p>Total pessoas deram reconhecimento </p>
          <p className="font-bold text-2xl">
            {generatePercentageString(
              activityTotalizer?.userRecognitionsMade,
              activityTotalizer?.totalUser
            )}
            %
          </p>
          <p>{activityTotalizer?.userRecognitionsMade} pessoas</p>
        </Totalizer>
        <Totalizer>
          <p>Total pessoas receberam reconhecimento </p>
          <p className="font-bold text-2xl">
            {generatePercentageString(
              activityTotalizer?.userRecognitionsReceived,
              activityTotalizer?.totalUser
            )}
            %
          </p>
          <p>{activityTotalizer?.userRecognitionsReceived} pessoas</p>
        </Totalizer>
        <Totalizer>
          <p>Total de pontos distribuídos </p>
          <p className="font-bold text-2xl">
            {activityTotalizer?.meritMoneyDitributed}
          </p>
          <p></p>
        </Totalizer>
        <Totalizer>
          <p>Total de pontos recebidos </p>
          <p className="font-bold text-2xl">
            {activityTotalizer?.meritusReceived}
          </p>
          <p></p>
        </Totalizer>
      </main>
    </div>
  );
};

export default ActivityStatistics;
