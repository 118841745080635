import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Form, Formik } from 'formik';
import Linkify from 'linkify-react';

import Card, { CardProps } from 'Components/Card';
import { UserContext } from 'Components/Context/UserContext';
import InputText from 'Components/Formik/InputText';
import InputTextArea from 'Components/Formik/InputTextArea';
import PopUp from 'Components/PopUp';
import ProductCarousel from 'Components/ProductCarousel';
import Tag from 'Components/Tag';

import {
  getProductDisplay,
  listProductDisplay,
  ProductDisplayModel,
  ProductDisplayModelWithVariantData,
  ProductVariantModel,
} from 'utils/api/productsDisplay';

import Navbar from 'Components/Navbar';
import ConfirmPurchase from 'Components/Popup/ConfirmPurchase';
import AutoMargin from 'Components/AutoMargin';
import { i18n } from 'utils/i18n/index';
import Select from 'react-select';
import { Option } from 'react-select/src/filters';

interface ProductProps {}

const initialValues = {
  quantity: 1,
  observation: '',
};

const selectStyle = {
  control: (base: {}) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    indicatorSeparator: { display: 'none' },
  }),
};

const Product: React.FC<ProductProps> = () => {
  const { user } = useContext(UserContext);
  const { productDisplayId } = useParams<{ productDisplayId: string }>();
  const [productDisplay, setProductDisplay] = useState<
    ProductDisplayModelWithVariantData | undefined | null
  >();
  const [selectedProduct, setSelectedProduct] = useState<
    ProductVariantModel | undefined | null
  >();
  const [products, setProducts] = useState<ProductDisplayModel[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>();
  const [formValues, setFormValues] =
    useState<typeof initialValues>(initialValues);

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    actions.setSubmitting(true);
    setFormValues(values);
    setShowConfirm(true);
    actions.setSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      if (!user?.companyId) return console.log('deu ruim');
      const products = await listProductDisplay(user.companyId, { limit: 5 });
      setProducts(products.data);
    })();
  }, [user]);

  useEffect(() => {
    if (productDisplayId === undefined) return;
    getProductDisplay(productDisplayId).then(setProductDisplay);
  }, [productDisplayId]);

  useEffect(() => {
    if (!productDisplay) return;
    const options = productDisplay.variantsData
      .map((x) => ({
        label: x.name,
        value: x.id,
        data: x,
      }))
      .sort((a, b) => a.data.meritus - b.data.meritus);
    setSelectOptions(options);
    setSelectedOption({ ...options[0] });
    setSelectedProduct(options[0].data);
  }, [productDisplay]);

  return (
    <div className="text-sm lg:text-base">
      <PopUp show={showConfirm}>
        <ConfirmPurchase
          product={selectedProduct}
          close={() => setShowConfirm(false)}
        />
      </PopUp>
      <Navbar />
      <AutoMargin className="max-w-7xl w-screen">
        <div className="px-4 lg:px-10">
          <div className="mt-7">
            <p className="text-xs">
              DASHBOARD / {i18n.t('storeMenu')}/{' '}
              <span className="text-primary">{productDisplay?.name}</span>
            </p>
          </div>
          <main className="mt-7 grid gap-8 grid-cols-1 lg:grid-cols-2">
            <div className="w-full h-full flex items-center justify-center">
              <ProductCarousel
                images={productDisplay?.images}
                className="w-full h-160 p-2 rounded relative bg-lighter-20"
              />
            </div>
            <div className="h-full flex flex-col justify-between items-start">
              <div className="flex flex-col items-start">
                <p className="text-black text-2xl font-normal">
                  {selectedProduct?.name}
                </p>
                <p className="font-light text-xl">{productDisplay?.brand}</p>
                {selectedProduct?.categories &&
                  !!selectedProduct.categories?.length && (
                    <div className="mt-3 col-span-2 flex-grow-0">
                      <div className="flex flex-row flex-wrap gap-2 mt-3">
                        {selectedProduct.categoriesDetails.map((category) => (
                          <Tag key={category.id} colorless>
                            {category.name}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  )}
                <div className="w-full mt-4 border rounded-input border-lighter-70">
                  <Select
                    isDisabled={false}
                    options={selectOptions}
                    name={'selected Option'}
                    className={`px-2 h-10 w-full 'border border-lighter-70' rounded-input focus:outline-none`}
                    styles={selectStyle}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={selectOptions.find(
                      (option) => option.value === selectedOption?.value
                    )}
                    onChange={(option) => {
                      setSelectedOption(option);
                      setSelectedProduct(option?.data);
                    }}
                    placeholder={'Loading...'}
                  />
                </div>
                <div className="flex-grow-0 mb-4 mt-3 py-2 px-8 rounded border border-lighter-70">
                  <p className="text-black text-2xl font-bold">{`R$ ${(
                    ((selectedProduct?.spreadRealValue ?? 0) +
                      (selectedProduct?.realPrice ?? 0)) /
                    100
                  ).toFixed(2)}`}</p>
                  <p className="text-sm">{i18n.t('Discriminado')}:</p>
                  <div className="text-sm ml-4">
                    <div className="flex items-baseline mt-2">
                      {i18n.t('Preço')}:
                      <p className="ml-2">
                        {((selectedProduct?.realPrice ?? 0) / 100).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex items-baseline mt-2">
                      {i18n.t('Taxa de serviço:')}:
                      <p className="ml-2">
                        {(
                          (selectedProduct?.spreadRealValue ?? 0) / 100
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="text-sm whitespace-pre-wrap mt-2">
                  <Linkify
                    options={{
                      className: 'text-primary hover:text-priamary-darker',
                      target: '_blank',
                      truncate: 35,
                    }}
                  >
                    {productDisplay?.instructions}
                  </Linkify>
                </p>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                <Form className="flex flex-col items-center justify-center w-full mt-3 gap-5">
                  {selectedProduct?.type === 'voucher' ||
                  selectedProduct?.type === 'voucher-test' ? null : (
                    <div className="w-full flex flex-col lg:flex-row gap-5 p-5 rounded bg-neutral ">
                      <label className="flex flex-col text-xs flex-grow-0">
                        <p className="text-black text-sm">
                          {i18n.t('quantity')}
                        </p>
                        <InputText
                          name="quantity"
                          placeholder={i18n.t('typeQuantity')}
                        />
                      </label>
                      <label className="flex flex-col text-xs w-full">
                        <p className="text-black text-sm">
                          {i18n.t('comments')}
                        </p>
                        <InputTextArea
                          name="observation"
                          placeholder={i18n.t('typeComments')}
                          className="h-20"
                        />
                      </label>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="rounded-full h-auto bg-primary-darker text-white px-14 py-3 w-full hover:bg-primary"
                  >
                    {i18n.t('exchangeMeritus')}
                  </button>
                </Form>
              </Formik>
            </div>
          </main>
          <div className="mt-5 col-span-2">
            <p className="text-xl text-black">{i18n.t('description')}</p>
            <p className="font-normal text-sm mt-5">
              <Linkify
                options={{
                  className: 'text-primary hover:text-priamary-darker',
                  target: '_blank',
                  truncate: 35,
                }}
              >
                {productDisplay?.desc}
              </Linkify>
            </p>
          </div>
          {products.length <= 1 ? null : (
            <div className="mt-10">
              <p className="text-xl text-black">{i18n.t('seeOtherProducts')}</p>
              <div className="flex w-full justify-start items-stretch gap-10 mt-3 mb-10 overflow-y-auto">
                {products.map((value, index) => {
                  if (value.id === productDisplayId) return null;
                  const attr = {
                    image: { src: value.images[0] },
                    title: value?.name,
                    button: {
                      text: `${i18n.t('knowMore')}`,
                      url: `/loja/${value?.id}`,
                    },
                    badge: {
                      text:
                        value.variants.length <= 1
                          ? (value.minPrice / 100).toFixed(2)
                          : `apartir de ${(value.minPrice / 100).toFixed(2)}`,
                    },
                  } as CardProps;
                  return (
                    <Card
                      {...attr}
                      key={value?.id}
                      className="w-64 flex-shrink-0 mb-2"
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </AutoMargin>
    </div>
  );
};

export default Product;
