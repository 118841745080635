import { UserContext } from 'Components/Context/UserContext';
import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'Components/Navbar';
import {
  addWalletFromTemplate,
  disableWallet,
  enableWallet,
  listCompanyWallets,
  Wallet,
} from 'utils/api/wallets';
import { toast } from 'react-toastify';
interface WalletProps {}

const Wallets: React.FC<WalletProps> = () => {
  const { user } = useContext(UserContext);

  // check if wallets are enable on company
  const [wallets, setWallets] = useState<Record<string, Wallet | null>>({
    Alimentação: null,
    'Bem-estar': null,
    Lazer: null,
    Escritório: null,
    Treinamento: null,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [toggleReRender, setToggleReRender] = useState(false);

  useEffect(() => {
    if (!user?.companyId) return;
    listCompanyWallets(user.companyId).then((wallets) => {
      const walletObject = wallets.reduce((prev, wallet) => {
        const object = {
          ...prev,
        };
        object[wallet.fromTemplate] = wallet;
        return object;
      }, {} as Record<string, Wallet>);

      setWallets(walletObject);
    });
  }, [user, toggleReRender]);

  const handleAddFromTemplate = (templateName: string) => {
    setSubmitting(true);
    const promise = addWalletFromTemplate(templateName);

    toast
      .promise(promise, {
        error: 'Algo deu errado',
        pending: 'Criando Carteira',
        success: {
          render: () => {
            setToggleReRender(!toggleReRender);
            return 'Carteira criada com sucesso';
          },
        },
      })
      .finally(() => setSubmitting(false));
  };

  const handleEnable = (walletId: string = '') => {
    if (walletId === '') {
      return toast.error('algo deu errado');
    }
    setSubmitting(true);
    const promise = enableWallet(walletId);

    toast
      .promise(promise, {
        error: {
          render: (e: any) => {
            return e.data?.response?.data || 'algo deu errado';
          },
        },
        pending: 'Habilitando Carteira',
        success: {
          render: () => {
            setToggleReRender(!toggleReRender);
            return 'Carteira habilitada com sucesso';
          },
        },
      })
      .finally(() => setSubmitting(false));
  };

  const handleDisable = (walletId: string = '') => {
    if (walletId === '') {
      return toast.error('algo deu errado');
    }
    setSubmitting(true);
    const promise = disableWallet(walletId);

    toast
      .promise(promise, {
        error: {
          render: (e: any) => {
            return e.data?.response?.data || 'algo deu errado';
          },
        },
        pending: 'Desabilitando Carteira',
        success: {
          render: () => {
            setToggleReRender(!toggleReRender);
            return 'Carteira desabilitada com sucesso';
          },
        },
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Navbar />
      <div className="px-0 md:px-10 mt-7">
        <p className="text-xs">
          <span className="text-primary px-3 ">Gestão de Cateiras</span>
        </p>
        <div className="flex items-center justify-between m-2">
          <p className="text-base md:text-3xl mt-4 text-black">Carteiras</p>
        </div>
        <p className="mt-20 text-black text-base"></p>
        <div className="mt-10 grid grid-cols-3 gap-4">
          <div className="w-100 border border-darkgray p-4 rounded">
            <div className="flex justify-between items-center">
              <div className="flex bg-primary shadow px-12 py-2 rounded-full text-white text-base items-center">
                <p>Geral</p>
              </div>
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar com produtos diversos.
            </p>
          </div>

          <div className="w-100 border border-yellow rounded p-4">
            <div className="flex justify-between items-center">
              <div className=" flex bg-yellow shadow px-5 py-2 rounded-full text-white text-base items-center ">
                <p>Alimentação</p>
              </div>
              {wallets['Alimentação'] === null ? null : !wallets[
                  'Alimentação'
                ] ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleAddFromTemplate('Alimentação')}
                >
                  Criar
                </button>
              ) : !wallets['Alimentação']?.isDisabled ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleDisable(wallets['Alimentação']?.id)}
                >
                  Desabilitar
                </button>
              ) : (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleEnable(wallets['Alimentação']?.id)}
                >
                  Habilitar
                </button>
              )}
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar em restaurantes, bares, lanchonetes,
              padarias entre outros estabelecimentos alimentares.
            </p>
          </div>

          <div className="w-100 border border-green p-4 rounded">
            <div className="flex justify-between items-center">
              <div className="flex bg-green shadow px-7 py-2 rounded-full text-white text-base items-center">
                <p>Bem-estar</p>
              </div>
              {wallets['Bem-estar'] === null ? null : !wallets['Bem-estar'] ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleAddFromTemplate('Bem-estar')}
                >
                  Criar
                </button>
              ) : !wallets['Bem-estar']?.isDisabled ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleDisable(wallets['Bem-estar']?.id)}
                >
                  Desabilitar
                </button>
              ) : (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleEnable(wallets['Bem-estar']?.id)}
                >
                  Habilitar
                </button>
              )}
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar com utensílios, atividades ou em
              ambientes que proporcionam um momento agradável destinado aos
              cuidados do corpo e da mente.
            </p>
          </div>

          <div className="w-100 border border-blue p-4 rounded">
            <div className="flex justify-between items-center">
              <div className="flex bg-blue shadow px-12 py-2 rounded-full text-white text-base items-center">
                <p>Lazer</p>
              </div>
              {wallets['Lazer'] === null ? null : !wallets['Lazer'] ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleAddFromTemplate('Lazer')}
                >
                  Criar
                </button>
              ) : !wallets['Lazer']?.isDisabled ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleDisable(wallets['Lazer']?.id)}
                >
                  Desabilitar
                </button>
              ) : (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleEnable(wallets['Lazer']?.id)}
                >
                  Habilitar
                </button>
              )}
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar em atividades que proporcinam um momento
              de descanso ou de entretenimento.
            </p>
          </div>

          <div className="w-100 border border-purple p-4 rounded">
            <div className="flex justify-between items-center">
              <div className="flex bg-purple shadow px-7 py-2 rounded-full text-white text-base items-center">
                <p>Escritório</p>
              </div>
              {wallets['Escritório'] === null ? null : !wallets[
                  'Escritório'
                ] ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleAddFromTemplate('Escritório')}
                >
                  Criar
                </button>
              ) : !wallets['Escritório']?.isDisabled ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleDisable(wallets['Escritório']?.id)}
                >
                  Desabilitar
                </button>
              ) : (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleEnable(wallets['Escritório']?.id)}
                >
                  Habilitar
                </button>
              )}
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar em materiais ou equipamentos uteis em
              sua rotina de escritório.
            </p>
          </div>

          <div className="w-100 border border-red p-4 rounded">
            <div className="flex justify-between items-center">
              <div className="flex bg-red shadow px-7 py-2 rounded-full text-white text-base items-center">
                <p>Treinamento</p>
              </div>
              {wallets['Treinamento'] === null ? null : !wallets[
                  'Treinamento'
                ] ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleAddFromTemplate('Treinamento')}
                >
                  Criar
                </button>
              ) : !wallets['']?.isDisabled ? (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleDisable(wallets['Treinamento']?.id)}
                >
                  Desabilitar
                </button>
              ) : (
                <button
                  className="border text-primary-darker rounded px-3 py-2 h-10 disabled:opacity-25"
                  disabled={isSubmitting}
                  onClick={() => handleEnable('Treinamento')}
                >
                  Habilitar
                </button>
              )}
            </div>
            <p className="pt-5">
              <strong>Descrição:</strong> Carteira destinada aos pontos que o
              funcionário poderá gastar em cursos que poderão ajudá-los a
              desenvolver suas habilidades profissionais.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
