// import jwtDecode from "jwt-decode";
import { domainCookie } from 'config';
import { logoutApi, tokenIsValid } from './api/users';

export const TOKEN_KEY = '@jwt';

type TokenPayload = {
  name: string;
  sub: string;
  iat: number;
  exp: number;
};

const payload = null as TokenPayload | null;

// export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
// export const isAuthenticated = () => tokenIsValid();

function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(
  cname: string,
  cvalue: string,
  exdays: number,
  domain: string
) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  const domainString = domain !== '' ? 'domain=' + domain + ';' : '';
  
  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';path=/;' + domainString;
}

export const getToken = () => {
  // localStorage.getItem(TOKEN_KEY);
  return getCookie('jwtCookie');
};

function delete_cookie(name: string) {
  setCookie(name, '', -2, '');
  setCookie(name, '', -2, domainCookie);
}

export const getTokenPayload = (): TokenPayload | null => {
  return payload;
  // const jwt = localStorage.getItem(TOKEN_KEY)

  // try {
  //   return jwtDecode(jwt || '')
  // } catch (error) {
  //   return  null
  // }
};

export const tokenIsExpired = () => {
  // const jwt = localStorage.getItem(TOKEN_KEY)
  // if( jwt === null) return true
  // const decoded = jwtDecode(jwt) as TokenPayload

  // if ( decoded.exp * 1000 < Date.now() ) return true

  if (payload === null) return true;
  if (payload.exp * 1000 < Date.now()) return true;

  return false;
};

// /**
//  *
//  * @param params {email: string, password: string}
//  * @returns userId if authenticated or null if failed
//  */
// export const authenticate = async (params: {email: string, password: string}) => {
//   const token = await userAuthenticate(params)
//   login(token)
//   return getTokenPayload()?.sub;
// }

export const loginCookie = (token: string, exp: number, domain: string) => {
  // localStorage.setItem(TOKEN_KEY, token);
  console.log('alo');

  setCookie('jwtCookie', token, exp, domainCookie);
};

export const logout = () => {
  // localStorage.removeItem(TOKEN_KEY);
  return logoutApi();
};

export const logoutCookie = () => {
  delete_cookie('jwtCookie');
  // localStorage.removeItem(TOKEN_KEY);\
  // return logoutApi()
};
