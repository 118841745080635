import React from 'react';

import AutoMargin from './AutoMargin';
import { Form, Formik } from 'formik';
import InputText from './Formik/InputText';
import { RequestPasswordRecoverySchema } from 'utils/validators/passwordRecovery';
import { requestPasswordRecoveryApi } from 'utils/api/passwordRecovery';
import { toast } from 'react-toastify';

interface RequestPasswordRecoveryProps {
  cancel: () => void;
}

const RequestPasswordRecovery: React.FC<RequestPasswordRecoveryProps> = ({
  cancel,
}) => {
  return (
    <AutoMargin className="w-96 bg-white rounded mt-5 p-8">
      <div className="font-medium text-xl text-black">Recupere sua senha</div>
      <div className="mb-16">
        Digite sua email para iniciar o processo de recuperação de senha
      </div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={RequestPasswordRecoverySchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          const promise = requestPasswordRecoveryApi(values);
          toast.promise(promise, {
            pending: 'Enviando email de recuperação de senha',
            success: {
              render() {
                actions.setSubmitting(false);
                cancel();
                return 'E-mail enviado';
              },
            },
            error: {
              render() {
                actions.setSubmitting(false);
                return 'Falha, tente novamente mais tarde';
              },
            },
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col">
            <label className="flex flex-col text-xs w-full mt-6">
              E-mail:
              <InputText name="email" placeholder="Digite sua email" />
            </label>
            <button
              disabled={isSubmitting}
              type="submit"
              className="py-2 bg-primary-darker text-white box-border rounded-full mt-10 disabled:opacity-30"
            >
              Recuperar Senha
            </button>
            <button
              onClick={cancel}
              className="py-2 bg-white border text-primary-darker box-border rounded-full mt-3 text-center"
            >
              Cancelar
            </button>
          </Form>
        )}
      </Formik>
    </AutoMargin>
  );
};

export default RequestPasswordRecovery;
