import React, { useState } from 'react';

interface ProductCarouselProps {
  images: string[] | undefined | null;
  className?: string;
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({
  images,
  className,
}) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);

  return !images || images.length === 0 ? (
    <div>Sem imagens para mostrar</div>
  ) : (
    <div className={`relative ${className}`}>
      <img
        src={images[selectedImage]}
        alt=""
        className="object-contain w-full h-full rounded"
      />
      <div className="absolute h-1/6 w-full pb-4 px-4 bottom-0 left-0">
        <div className="inline-flex gap-3 p-3 rounded bg-lighter-70 bg-opacity-50 h-full">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              className="object-contain shadow h-auto w-auto rounded-input"
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
