import  i18n from 'i18next'
//import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { messages } from './translations/index'
import {I18N_STORAGE_KEY} from "Components/LanguageSwitcher" 


export enum Languages {
    PORTUGUESE = "ptBR",
    // ENGLISH = "en",
    // SPANISH = "es"
}

i18n
    .use(LanguageDetector)
    .init({
        debug: false,
        defaultNS:'translations',
        fallbackLng:Languages.PORTUGUESE,
        ns:['translations'],
        resources: messages
})

export { i18n }

export const GetLocaleString = () => { 
    if ( window.localStorage.getItem(I18N_STORAGE_KEY) === Languages.PORTUGUESE){
        return "pt-BR"
    // }else if (window.localStorage.getItem(I18N_STORAGE_KEY) === Languages.ENGLISH){
    //     return "en-US"
    // }else if (window.localStorage.getItem(I18N_STORAGE_KEY) === Languages.SPANISH){
    //     return "es-ES"
    }else{
        return "pt-BR"
    }
}
