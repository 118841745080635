import React, { useState, useContext, useCallback, useEffect } from 'react';
import { GroupModel, GroupStatus, useSWRListGroupByCompany } from 'utils/api/group';

import { UserContext } from 'Components/Context/UserContext';
import { GroupDetailsType } from 'Components/GroupsDetails';
import TableComponent, {
  TableComponentColumns,
} from 'Components/TableComponent';
import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';
import  GroupsFilters  from 'Components/Filters/GroupFilter' 

interface GroupListProps {
  selectGroupDetails: (
    group: GroupModel,
    type?: GroupDetailsType,
    then?: (selectedGroup: GroupModel) => any
  ) => void;
  setAndShowUpdateGroup: (group: GroupModel, callback?: Function) => void;
  toggleRefreshData?: boolean;
}

const GroupList: React.FC<GroupListProps> = ({
  selectGroupDetails,
  setAndShowUpdateGroup,
  toggleRefreshData = false,
}) => {
  const perPage = 15;

  const { user } = useContext(UserContext);

  const [prevToggleRefreshData, setPrevToggleRefreshData] =
    useState(toggleRefreshData);

  const [renderKey, setRenderKey] = useState(0);
  const [page, setPage] = useState(1);
  const [groupName, setGroupName] = useState('');
  const [groupStatus, setGroupStatus] = useState(GroupStatus.ENABLED)
  const { data, metadata, isError, isLoading } = useSWRListGroupByCompany(
    user?.companyId,
    {
      offset: (page - 1) * perPage || 0,
      limit: perPage,
      groupByName: groupName,
      groupByStatus: groupStatus
    },
    renderKey
  );

  const onChangePage = useCallback(async (page: number) => {
    setPage(page);
  }, []);

  const afterAction = (selectedGroup: GroupModel) => {
    setRenderKey(Math.random());
  };

  useEffect(() => {
    if (toggleRefreshData === prevToggleRefreshData) {
      return;
    }

    setRenderKey(Math.random());
    setPrevToggleRefreshData(toggleRefreshData);
  }, [prevToggleRefreshData, toggleRefreshData]);

  const [showFilters, setShowFilters] = useState(false);
  const handleSetShowFilters = () => {
    setShowFilters(!showFilters)
  }

  const handleFilters = async (filter: Record<any, any>) => {
    setGroupName(filter.groupByName);
    setGroupStatus(filter.groupByStatus);
  };

  const columns = React.useMemo<TableComponentColumns>(
    () => [
      {
        id: 'name',
        name: 'Nome',
        selector: (row) => row.name,
        reorder: true,
        grow: 1,
      },
      {
        id: 'desc',
        name: 'Descrição',
        selector: (row) => row.desc,
        reorder: true,
        grow: 3,
      },
      {
        id: 'action',
        name: 'Ação',
        selector: (row) => row.action,
        reorder: true,
        grow: 3,
        cell: (row) => (
          <div>
            {row.isDisabled ? (
              <button
                className="cursor bg-white text-primary-darker rounded-input border border-primary-darker mt-4 mb-2 px-1 md:px-2 py-1 md:mr-6"
                onClick={() =>
                  selectGroupDetails(
                    row as GroupModel,
                    GroupDetailsType.ENABLE,
                    afterAction
                  )
                }
              >
                Habilitar
              </button>
            ) : (
              <button
                className="cursor bg-white text-primary-darker rounded-input border border-primary-darker mt-4 mb-2 px-1 md:px-2 py-1 mr-3"
                onClick={() => {
                  selectGroupDetails(
                    row as GroupModel,
                    GroupDetailsType.DISABLE,
                    afterAction
                  );
                }}
              >
                Desabilitar
              </button>
            )}
            <button
              className="cursor bg-white text-primary-darker rounded-input border border-primary-darker px-2 py-1 mr-3"
              onClick={() => setAndShowUpdateGroup(row as GroupModel)}
            >
              Editar
            </button>
          </div>
        ),
      },
    ],
    [selectGroupDetails, setAndShowUpdateGroup]
  );

  return (
    <>
      {/*<p className="text-xl text-black">Lista de Grupos</p>*/}
      <div className="w-full gap-2 mt-3">
        <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
          <div className= 'flex items-center px-2 py-2 w-full flex cursor-pointer justify-between'
          onClick={handleSetShowFilters}>
            <div className='flex'>
              <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black"/>
                {showFilters? "Ocultar Filtros": "Mostrar Filtros"}
            </div>
            <div>
              {showFilters? <ArrowUp/>: <ArrowDown/>}  
            </div>
          </div>
          {showFilters===true &&
            <GroupsFilters handleFilters={handleFilters} />
          }
        </div>
        <div className="mt-2 shadow px-7 pb-7">
          <TableComponent
            // title="Lista de Grupos"
            className="w-full"
            data={data}
            columns={columns}
            isLoading={isLoading}
            pagination
            paginationServer
            paginationTotalRows={metadata?.total}
            onChangePage={onChangePage}
            perPage={perPage}
            resetPageToggle={false}
          />
        </div>
        {/*groups.map((group) => {
            return (
              <div
                key={group.id}
                className="flex justify-between items-center mt-3 p-3 bg-lighter-20 rounded"
              >
                <div className="flex gap-3 w-3/4 ">
                  <div className="md:ml-3">
                    <p className="flex-wrap text-xs md:text-base">Nome: {group.name}</p>
                    <p className="flex-wrap text-xs md:text-base">Descrição: {group.desc}</p>
                    <p className="flex-wrap text-xs md:text-base">
                      Status: {group.isDisabled ? 'Desabilitado' : 'Habilitado'}
                    </p>
                  </div>
                </div>
                <div className='flex'>
                  {group?.isDisabled ? (
                    <button
                      className="border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary w-20 md:w-24 text-xs md:text-base"
                      onClick={() =>
                        selectGroupDetails(
                          group,
                          GroupDetailsType.ENABLE,
                          afterAction
                        )
                      }
                    >
                      Habilitar
                    </button>
                  ) : (
                    <button
                      className="border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary w-20 md:w-24 text-xs md:text-base"
                      onClick={() =>
                        selectGroupDetails(
                          group,
                          GroupDetailsType.DISABLE,
                          afterAction
                        )
                      }
                    >
                      Desabilitar
                    </button>
                  )}
                   <button
                      className="border border-primary-darker p-1 ml-2 rounded-input text-primary-darker hover:border-primary hover:text-primary w-20 md:w-24 text-xs md:text-base"
                      onClick={()=>setAndShowUpdateGroup(group)}
                    >
                      Editar
                    </button>
                </div>
              </div>
            );
          })*/}
      </div>
    </>
  );
};

export default GroupList;
