import React from 'react';

interface AutoMarginProps {
  className?: string;
}

const AutoMargin: React.FC<AutoMarginProps> = ({ children, className }) => {
  return (
    <div className="flex">
      <div className="flex-margin w-1/3" />
      <div className={className}>{children}</div>
      <div className="flex-margin" />
    </div>
  );
};

export default AutoMargin;
