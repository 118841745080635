import api from './axios';
import { CompanyModel } from './company';

export type Wallet = {
  id: string;
  name: string;
  desc: string;
  categories: Array<{
    id: string;
    name: string;
    desc: string;
  }>;
  companyId: string;
  style: {
    theme: string;
  };
  isDisabled: boolean;
  fromTemplate: string;
};

// export const listCompanyWallets = async (companyId: CompanyModel['id']) => {
//   // todo change to company specific and pagination
//   const { data } = await api.get<Wallet[]>(`wallet`, {});
//   return data
// };

export const listCompanyWallets = async (companyId: CompanyModel['id']) => {
  const { data } = await api.get<Wallet[]>(
    `wallet/byCompany/${companyId}`,
    {}
  );
  return data;
};

export const addWalletFromTemplate = async (templateName: string) => {
  const { data } = await api.post<Wallet>(`wallet/fromTemplate/${templateName}`, {});
  return data;
};

export const enableWallet = async (walletId: string) => {
  const { data } = await api.put<boolean>(`wallet/enable/${walletId}`, {});
  return data;
};

export const disableWallet = async (walletId: string) => {
  const { data } = await api.put<boolean>(`wallet/disable/${walletId}`, {});
  return data;
};
