import { Formik, FormikHelpers } from 'formik';
import InputText from 'Components/Formik/InputText';
import SelectField from 'Components/Formik/SelectField';
import { GroupStatus } from 'utils/api/group';

interface GroupFiltersProps {
  handleFilters: (filters: Record<any, any>) => void;
}

const GroupsFilters: React.FC<GroupFiltersProps> = ({
  handleFilters,
}) => {
    
  const status = [
    { value: 'enabled', label: 'Habilitado' },
    { value: 'disabled', label: 'Desabilitado' },
    { value: undefined , label: 'Todos' }
  ];
  const initialValues = {
    groupByName: '',
    groupByStatus: GroupStatus.ENABLED,
  };

  const handleSubmit = async (
    values: Partial<typeof initialValues>,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);

    
    const keys = Object.keys(values) as Array<keyof typeof values>;
    keys.forEach((key) => (values[key] === '' ? (values[key] = undefined) : {}));

    await handleFilters(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm, resetForm }) => (
        <>
            <div className='w-full'>
              <div className="w-full flex lg:gap-8 gap-4 justify-start items-start flex-wrap py-3">
                <div
                  className= 'flex flex-col py-1 px-1' 
                >
                    <p className='text-sm mb-2'>Nome:</p>
                    <InputText
                    name="groupByName"
                    placeholder= "Digite o nome"
                    className="w-52"
                    />
                </div> 

                <div
                  className='flex flex-col py-1 px-1'
                >
                    <p className='text-sm mb-2'>Status:</p>
                    <SelectField
                    name="groupByStatus"
                    className="w-40 rounded-input"
                    options={status}
                    placeholder="Escolha"
                  />
                </div>                           
            
                <div className="w-full flex justify-end mt-2 gap-7">
                  <button
                    className="text-primary mt-2"
                    onClick={() => {
                      resetForm({
                      values: {
                        ...initialValues,
                      },
                    });
                    submitForm();
                  }
                  }
                  >
                    Limpar
                  </button>
                  <button
                    className="bg-primary-darker rounded px-6 py-2 text-white mt-2 disabled:opacity-30"
                    type="submit"
                    onClick={submitForm}
                  >
                    Procurar
                  </button>
                </div>

              </div>
            </div>
        </>
      )}
    </Formik>
  );
};

export default GroupsFilters;