import api from './axios';
import { DateRangeDates } from 'Components/Formik/DateRange';

export type ReportModel = {
    companyId: string,
    dateInicial?:string | undefined, 
    dateFinal?:  string | undefined,
    reportType: string     
  };
export const generate = async (companyId: string, payload: ReportModel) => {
    const { data } = await api.request({
      url: `/report/${companyId}`,
      data: payload,
      method: 'POST',
    });
    return data;
  };