import React, { useState, useContext, useCallback } from 'react';
import {
   useSWRListPurchaseByCompany ,
} from 'utils/api/purchase';

import { UserContext } from 'Components/Context/UserContext';
import TableComponent, {
  TableComponentColumns,
} from 'Components/TableComponent';


const PurchaseTable = () => {
   const perPage = 15;

   const { user } = useContext(UserContext);

   const [renderKey, setRenderKey] = useState(0);
   const [page, setPage] = useState(1);
  
   const { data, metadata, isLoading } = useSWRListPurchaseByCompany (
    user?.companyId,
    {
      companyId: user?.companyId,  
      offset: (page - 1) * perPage || 0,
      limit: perPage,
    },
    renderKey
  );

   const onChangePage = useCallback(async (page: number) => {
    setPage(page);
   }, []);


   const columns = React.useMemo<TableComponentColumns>(
    () => [
      {
        id: 'productDetails',
        name: 'Produto',
        selector: (row) => row.productDetails,
        reorder: true,
        cell: (row) => (
            <div className="flex flex-row gap-1 flex-wrap">
              {row.productDetails
                ? row.productDetails.map((product: any) => (
                    <p
                      key={product._id}
                      className=""
                    >
                      {product.name}
                    </p>
                  ))
                : undefined}
            </div>
          )
      },
      {
        id: 'quantity',
        name: 'Quantidade',
        selector: (row) => row.quantity,
        reorder: true,
        //right: true,
      },
      {
        id: 'meritus',
        name: 'meritus',
        selector: (row) => row.meritus,
        reorder: true,
       // right: true,
      },
      {
        id: 'userDetails',
        name: 'Usuário',
        selector: (row) => row.userDetails,
        reorder: true,
        cell: (row) => (
            <div className="flex flex-row gap-1 flex-wrap">
              {row.userDetails
                ? row.userDetails.map((user: any) => (
                    <p
                      key={user._id}
                      className="bg-neutral-darker rounded-full px-2"
                    >
                      {user.name}
                    </p>
                  ))
                : undefined}
            </div>
          ),
      },
      {
        id: 'status',
        name: 'Status',
        selector: (row) => row.status,
        reorder: true,
        omit: true,
      },
      {
        id: 'createdAt',
        name: 'Data',
        selector: (row) => row.createdAt,
        reorder: true,
      },    
    ],
    []
  );

   return (
    <>
      <div className="w-full gap-2 mt-3">
        <div className="mt-2 shadow px-7 pb-7">
          <TableComponent
            title="Lista de Produtos Trocados"
            className="w-full"
            data={data}
            columns={columns}
            isLoading={isLoading}
            pagination
            paginationServer
            paginationTotalRows={metadata?.total}
            onChangePage={onChangePage}
            perPage={perPage}
            resetPageToggle={false}
          />
        </div>
      </div>
    </>
  );
};

export default PurchaseTable;