import axios from 'axios';
import { getToken } from 'utils/jwt';
import { baseURL } from '../../config';

const api = axios.create({ baseURL: baseURL });

api.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${getToken()}` };
  return config;
});

export default api;
