import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  UserContextProvider,
} from 'Components/Context/UserContext';

import Login from './Pages/Login';
import Menu from './Pages/Menu';
import Logout from 'Pages/Logout';
import Users from 'Pages/Users';
import Groups from 'Pages/Groups';
import Missions from 'Pages/Missions';
import Statistics from 'Pages/Statistics';
import Products from 'Pages/Products';
import history from './utils/history';
import Wallets from 'Pages/Wallets';
import Store from 'Pages/Store';
import Product from 'Pages/Product';

import 'react-toastify/dist/ReactToastify.min.css';
import './global.css';

function App() {
  return (
    <Router history={history}>
      <UserContextProvider>
        <Switch>
        <Route path="/" exact component={Login} />
          <Route path="/menu" exact component={Menu} />
          <Route path="/usuarios" exact component={Users} />
          <Route path="/missoes" exact component={Missions}/>
          <Route path="/estatisticas" exact component={Statistics} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/grupos" exact component={Groups}/> 
          <Route path="/produtos" exact component={Products}/>
          <Route path="/carteiras" exact component={Wallets}/> 
          <Route path="/loja" exact component={Store}/> 
          <Route
            path="/loja/:productDisplayId"
            exact
            component={Product}
          />
          <Route path="*">
            <div>404! url não encontrado</div>
          </Route>
        </Switch>
      </UserContextProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
