import Square from 'Components/Square';
import React from 'react';

interface GhostProfilePicturesProps {
  className?: string;
  src?: string;
  alt: string;
  name?: string;
}

const getInitials = (name?: string) => {
  if (!name) return '';
  return name.trim()[0];
};

const GhostProfilePicture: React.FC<GhostProfilePicturesProps> = ({
  className,
  src,
  alt,
  name,
}) => {
  return (
    <Square className={`${className} rounded-full bg-neutral`}>
      {src && src !== 'a' ? (
        <div
          className="absolute object-contain rounded-full w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${src})` }}
        ></div>
      ) : (
        <div className="flex items-center justify-center absolute w-full transform top-0 translate-y-1/2">
          <div className="w-3/5">
            <svg viewBox="0 0 30 20">
              <text x="50%" y="100%" textAnchor="middle">
                {getInitials(name)}
              </text>
            </svg>
          </div>
        </div>
      )}
    </Square>
  );
};

export default GhostProfilePicture;
