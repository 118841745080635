import { UserContext } from 'Components/Context/UserContext';
import SelectField from 'Components/Formik/SelectField';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import PopUp from 'Components/PopUp';
import ReportsFilter from 'Components/ReportsFilter';
interface ActivityStatisticsProps {}

const Reports: React.FC<ActivityStatisticsProps> = () => {
  const { user } = useContext(UserContext);
  const [showReportsFilter, setshowReportsFilter] = useState(false);
  const [report, setReport] = useState('meritusBalance');

  const initialValues = {
    report: 'meritusBalance',
  };

  useEffect(() => {
    if (!user?.companyId) return console.log('deu ruim');
  });

  const handleSubmit = () => {};

  const handleCancel = () => {
    setshowReportsFilter(false);
  };

  return (
    <div>
      <PopUp show={showReportsFilter}>
        <ReportsFilter cancel={handleCancel} report={report} />
      </PopUp>
      <div className="flex flex-col items-start">
        <p className="mb-4">Selecione o tipo de relatório que deseja gerar:</p>
        <p className="text-xs">
          <strong>Balanço de meritus:</strong> Esse relatório contém a
          quantidade de meritus que cada usuário ganhou e gastou no período de
          tempo selecionado.
        </p>
        <p className="text-xs mb-4">
          <strong>Transações:</strong> Esse relatório contém as transações
          realizadas, a data em que foram realizadas, quem reconheceu, quem foi
          reconhecido, os valores e a mensagem associada a transação
        </p>
        <div className="flex w-4/5">
          <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            <SelectField
              name="report"
              options={[
                { value: 'meritusBalance', label: 'Balanço de meritus' },
                { value: 'transactionsReport', label: 'Transações' },
              ]}
              className="my-3 w-3/5"
              onChange={(options) => setReport(options.value)}
            />
          </Formik>
          <main>
            <button
              className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30 ml-4 mt-2"
              onClick={() => {
                setshowReportsFilter(true);
                setReport(report);
              }}
            >
              Gerar relatório
            </button>
          </main>
        </div>
        <p className="text-xs">
          Nota: Ao clicar em gerar relatório, pode levar alguns instantes para
          que o download do seu arquivo comece.
        </p>
      </div>
    </div>
  );
};

export default Reports;
