import { ReactComponent as ProfileIcon } from 'assets/vectors/profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/vectors/logout.svg';
import { ReactComponent as XIcon } from 'assets/vectors/x.svg';
import { ReactComponent as ShuffleIcon } from 'assets/vectors/shuffle.svg';
import { ReactComponent as GroupIcon } from 'assets/vectors/group.svg';
import { ReactComponent as StocksIcon } from 'assets/vectors/stocks.svg';
import { ReactComponent as MissionIcon } from 'assets/vectors/mission.svg';
import { ReactComponent as ProductsIcon } from 'assets/vectors/loja.svg';
import { ReactComponent as WalletIcon } from 'assets/vectors/wallet.svg';
import { ReactComponent as LojaIcon } from 'assets/vectors/loja.svg';
import Navbar from '../Components/Navbar';
import history from '../utils/history';
import { merituURL } from 'config';
import { Link } from 'react-router-dom';

interface MenuProps {}

const Menu: React.FC<MenuProps> = () => {
  return (
    <div className="bg-primary-darker h-screen w-screen overflow-hidden">
      <Navbar alternative />
      <div className="bg-primary-darker w-full  h-full flex justify-center items-center pt-7">
        <div className="w-4/5 md:w-3/5">
          <div className="flex justify-between">
            <p className="text-4xl text-white">Onde você deseja ir?</p>
            <XIcon
              className="text-white stroke-current cursor-pointer"
              onClick={() => history.goBack()}
            />
          </div>
          <div className="flex flex-col md:grid md:grid-cols-4 lg:grid-cols-5 md:gap-5 md:mt-10">
            <div className="cursor-pointer h-24 relative mt-5 w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/estatisticas"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <StocksIcon className="md:mt-8 fill-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Estatística</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/usuarios"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <ProfileIcon className="md:mt-8 fill-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Usuários</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 md:w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/grupos"
                className="w-full md:h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <GroupIcon className="md:mt-8 fill-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Grupos</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 md:w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/missoes"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <MissionIcon className="md:mt-8 stroke-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Missão</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 md:w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/produtos"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <ProductsIcon className="md:mt-8 stroke-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Produtos</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 md:w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/loja"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <LojaIcon className="lg:mt-8 stroke-current  lg:w-1/3 w-1/6" />
                <p className=" text-xl lg:text-2xl font-normal">Loja</p>
              </Link>
            </div>
            <div className="cursor-pointer relative mt-5 md:w-full md:h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <Link
                to="/carteiras"
                className="w-full h-full flex  md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
              >
                <WalletIcon className="md:mt-8 stroke-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Carteiras</p>
              </Link>
            </div>
            <a href={merituURL}>
              <div className="cursor-pointer relative mt-5 w-full h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
                <div className="w-full h-full flex md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
                  <ShuffleIcon className="md:mt-8 fill-current md:w-1/2 w-1/5" />
                  <p className=" text-xl md:text-2xl font-normal">App</p>
                </div>
              </div>
            </a>
            <div className="cursor-pointer relative mt-5 w-full h-auto text-white bg-primary hover:bg-white hover:text-darker-90 md:pb-full rounded-input">
              <div
                className="w-full h-full flex md:flex-col py-3 md:py-0 gap-3 justify-around items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2"
                onClick={() => history.push('/logout')}
              >
                <LogoutIcon className="md:mt-8 fill-current md:w-1/3 w-1/6" />
                <p className=" text-xl md:text-2xl font-normal">Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
