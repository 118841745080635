import React from 'react';
import { generate } from 'utils/api/report';
import DateSelectorReport from 'Components/Formik/DateSelectorReport';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers, Form } from 'formik';
import InputText from './Formik/InputText';
import { reportSchema } from 'utils/validators/report';

interface ReportsProps {
  cancel: (refresh?: boolean) => void;
  report: string;
}

const ReportsFilter: React.FC<ReportsProps> = ({ cancel, report }) => {
  const { user } = useContext(UserContext);

  const initialValues = {
    dateMin: '',
    dateMax: '',
    reportType: report,
    reportPlaceholder: '',
  };

  const handleSubmit = (
    values: Partial<typeof initialValues>,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    actions.setSubmitting(true);

    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { dateMin, dateMax } = values;
    let processedMaxDate = ''
    let processedMinDate = ''

    if (dateMax) {
      processedMaxDate = new Date(dateMax).toISOString().split('T')[0]
    }


    if (dateMin) {
      processedMinDate = new Date(dateMin).toISOString().split('T')[0]
    }

    const processedValues = {
      companyId: user.companyId,
      dateMin: processedMinDate,
      dateMax: processedMaxDate,
      reportType: report,
    };

    const promise = generate(user.companyId, processedValues);

    toast.promise(promise, {
      pending: 'Gerando relatório',
      success: {
        render(res: any) {
          const url = res.data?.url;
          const createDownloadElement = document.createElement('a');
          createDownloadElement.setAttribute('href', url);
          createDownloadElement.click();
          actions.setSubmitting(false);
          cancel(true);
          return 'Relatório gerado';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded w-11/12 md:w-5/12">
      <Formik
        validationSchema={reportSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">
              Definir período do relatório
            </p>
            <p className="mt-8">Tipo do relatório:</p>
            <InputText
              name="report"
              placeholder={
                report === 'meritusBalance'
                  ? 'Balanço de meritus'
                  : 'Transações'
              }
              disabled
            />
            <p className="mt-8">Data Inicial:</p>
            <DateSelectorReport name="dateMin" className="text-xs z-50" />

            <p className="mt-8">Data Final:</p>
            <DateSelectorReport name="dateMax" className="text-xs z-40" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Gerar relatório
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReportsFilter;
