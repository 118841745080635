import React from 'react';
import { Formik, Form}  from 'formik';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { groupCreate } from 'utils/api/group';
import { toast } from 'react-toastify';
import { createGroupSchema } from 'utils/validators/createGroup';
import { UserContext } from './Context/UserContext';


interface CreateGroupProps {
  cancel: (refresh?:boolean) => void;
}

const AddGroup: React.FC<CreateGroupProps> = ({ cancel }) => {
  const { user } = useContext(UserContext);
  const initialValues = {
    id:'',
    name: '',
    companyId: '',
    users: [],
    isDisabled: false,
    desc: '' 
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { name, desc } = values;
    const processedValues = {
        name,
        companyId: user.companyId,  
        desc, 
    };

    const promise = groupCreate(processedValues);
    toast.promise(promise, {
      pending: 'Criando grupo',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel(true);
          return 'Grupo criado com sucesso';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  

  return (
    <div className="bg-white p-8 rounded w-11/12 md:w-5/12">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema = { createGroupSchema }
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Crie um grupo</p>
            <p className="mt-8">Nome do grupo:</p>
            <InputText name="name" placeholder="Digite o nome do grupo" />
            <p className="mt-8">Fale um pouco sobre o grupo</p>
            <InputText name="desc" placeholder="Descrição" />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-between mt-4">
              <div></div>
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={()=>cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Adicionar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddGroup;