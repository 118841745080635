import React, { useState, useEffect } from 'react';
import { getUser, logoutApi, UserModel, whoAmI } from 'utils/api/users';
import history from 'utils/history';
// import { getTokenPayload, logout } from 'utils/jwt';
import { useRouteMatch } from 'react-router-dom';
import { logoutCookie } from 'utils/jwt';
import { toast } from 'react-toastify';

export type UserContext = {
  // undefined: not initialized, null: invalid session, string: valid session
  userId: string | undefined | null;
  user: Partial<UserModel> | undefined | null;
  manager: string[] | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserContext['user']>>;
  updateUserContext: () => void;
  login: () => Promise<void>;
  logout: () => void;
};

export const UserContext = React.createContext<UserContext>({
  userId: undefined,
  manager: undefined,
  user: undefined,
  setUser: () => {},
  updateUserContext: () => {},
  login: () => {
    return new Promise(() => {});
  },
  logout: () => {},
});

export const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserContext['user']>();
  const [userId, setUserId] = useState<UserContext['userId']>();
  const [manager, setManager] = useState<UserContext['manager']>();
  const isLoginPage = useRouteMatch({ path: '/', exact: true });
  const isPasswordRecovery = useRouteMatch({
    path: '/recuperarSenha/:passwordRecoveryId',
    exact: true,
  });

  const logout = () => {
    logoutCookie();
    logoutApi().finally(() => {
      setUser(null);
      setUserId(null);
      setManager(undefined);
      history.push('/');
    });
  };

  const login = async () => {
    try {
      setUser(undefined);
      const { userId, manager } = await whoAmI();
      if (!manager?.length) {
        toast.error('Usuário não é manager');
        setUser(null);
        return;
      }
      const user = await getUser(userId);
      setUser(user);
      setUserId(userId);
      setManager(manager);
    } catch (error) {
      throw error;
    }
  };

  const updateUserContext = () => {
    if (!userId) {
      setUser(null);
      history.replace('/');
    } else {
      getUser(userId).then(setUser);
    }
  };

  // decision making with user id
  useEffect(() => {
    if (isPasswordRecovery) return;
    if (isLoginPage && userId && user) {
      return history.replace('/menu');
    }

    if (userId === undefined) return;

    if (userId === null) {
      if (isLoginPage) return;
      return history.replace('/');
    }

    if (user === undefined || user === null) {
      getUser(userId).then(setUser);
    }
  }, [user, isLoginPage, isPasswordRecovery, userId]);

  // setting userId
  useEffect(() => {
    if (isPasswordRecovery) return;
    // if (isLoginPage) return;

    if (userId !== undefined) return;

    whoAmI()
      .then(({ userId, manager }) => {
        if (!manager?.length) {
          logoutCookie();
          history.push('/');
          toast.error('Usuário não é manager');
          return;
        }
        setUserId(userId);
        setManager(manager);
      })
      .catch(() => {
        logoutCookie();
        setUserId(null);
        setUser(null);
        setManager(undefined);
      });
  }, [isLoginPage, isPasswordRecovery, userId]);

  return (
    <UserContext.Provider
      value={{
        user,
        userId,
        setUser,
        updateUserContext,
        login,
        logout,
        manager,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
