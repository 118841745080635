import useSWR from 'swr';
import api from './axios';
import { CompanyModel } from './company';
import { swrFetcher } from './swr';
import { UserModel } from './users';

export type GroupModel = {
  id: string;
  name: string;
  companyId: CompanyModel['id'];
  users: Array<UserModel['id']>;
  isDisabled?: boolean | undefined;
  desc: string;
};

export enum GroupStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const groupCreate = async (
  payload: Omit<GroupModel, 'id' | 'users' | 'isDisabled'>
) => {
  const { data } = await api.request({
    url: `/group `,
    data: payload,
    method: 'POST',
  });
  return data;
};

export type listDisplayGroupResult = Array<{
  displayName: string;
  name: string;
  id: string;
  description: string;
}>;

export const listGroupByCompany = async (companyId: string) => {
  const { data } = await api.request({
    method: 'get',
    url: `/group/byCompany/${companyId}`,
  });
  return data as { data: GroupModel[]; metadata: { total: number } };
};

export const useSWRListGroupByCompany = (
  companyId: CompanyModel['id'] | undefined,
  params?: {
    offset?: number;
    limit?: number;
    groupByName?: string;
    groupByStatus?: GroupStatus;
  },
  requestKey: number = 0
) => {
  const { data, error } = useSWR<any>(
    companyId
      ? [`/group/byCompany/${companyId}`, { params }, requestKey]
      : null,
    swrFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );

  return {
    data: data?.data.data,
    metadata: data?.data.metadata,
    isLoading: !error && !data,
    isError: error,
  };
};

export const enableGroup = async (groupId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/group/enable/${groupId}`,
  });
  return data;
};

export const disableGroup = async (groupId: string): Promise<boolean> => {
  const { data } = await api.request({
    method: 'put',
    url: `/group/disable/${groupId}`,
  });
  return data;
};

export const updateGroup = async (groupId: string, params: any) => {
  await api.request({
    method: 'PUT',
    url: `/group/${groupId}`,
    data: params,
  });
};
