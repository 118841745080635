import { ErrorMessage, Field, FieldProps } from 'formik';
import Select, { components, OptionsType, OptionTypeBase } from 'react-select';
import React from 'react';
import { Option } from 'react-select/src/filters';
import GhostProfilePicture from 'Components/Ghost/GhostProfilePicture';


export type SelectOptions = OptionsType<OptionTypeBase>;

interface SelectUserFieldProps {
  name: string;
  options: SelectOptions;
  className?: string;
  placeholder?: string;
  isLoading?: boolean;
  noOptionsMessage?: string;
  isDisabled?: boolean
}

const SelectUserField: React.FC<SelectUserFieldProps> = (props) => {
  return <Field component={SelectUserChild} {...props} />;
};

const selectStyle = {
  control: (base: {}) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    indicatorSeparator: { display: 'none' },
  }),
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Sem opções</span>
    </components.NoOptionsMessage>
  );
};

interface SelectChildProps extends FieldProps, SelectUserFieldProps {}

const SelectUserFormat = (option: any) => {
  return (
    <div className="flex items-center h-10">
      <div className="w-8">
        <GhostProfilePicture
          src={option.img}
          alt=""
          name={option.displayName}
          className="w-7 rounded-full"
        />
      </div>
      <div className="ml-3">
        <p className="text-sm">{option.displayName}</p>
        <p className="text-xs font-light">{option.email}</p>
      </div>
    </div>
  );
};

const SelectUserChild: React.FC<SelectChildProps> = ({
  field,
  form,
  meta,
  options,
  className,
  placeholder,
  isLoading,
  noOptionsMessage,
  isDisabled,
}) => {
  const handleValue = () => {
    const value = options.find((option) => option.value === field.value);
    return value ? value : null;
  };
  return (
    <div className={`${className} relative`}>
      <Select
        options={options}
        name={field.name}
        className="h-10 border border-lighter-70 rounded-input focus:outline-none bg-white"
        styles={selectStyle}
        components={{
          IndicatorSeparator: () => null,
          NoOptionsMessage,
        }}
        value={handleValue()}
        onChange={(option: Option) =>
          {form.setFieldValue(field.name, option.value)}
        }
        onBlur={field.onBlur}
        placeholder={placeholder}
        formatOptionLabel={SelectUserFormat}
        isLoading={isLoading}
        noOptionsMessage={() => noOptionsMessage || 'no Option'}
        isDisabled = {isDisabled}
      />
      <p className="text-xs text-red absolute">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default SelectUserField;
