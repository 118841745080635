import { UserContext } from 'Components/Context/UserContext';
import React, { useContext } from 'react';

interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
    const {logout} = useContext(UserContext);
    return (
      <div>
        Logout
        {logout()}
      </div>
    );
};

export default Logout;
