import React from 'react';
import { Link } from 'react-router-dom';
import Tag from './Tag';

export interface CardProps {
  title: string;
  desc?: string;
  image: { src: string; rounded?: boolean };
  button?: { url: string; text: string };
  badge?: { text: string | number; className?: string };
  className?: string;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className={props.className}>
      <div className="flex flex-grow-0 flex-shrink-0 h-full w-full flex-col items-center justify-around rounded-xl bg-gray p-9">
        <span className="relative inline-block">
          <img
            src={props.image.src}
            alt=""
            className={`${
              props.image.rounded && 'rounded-full'
            } bg-neutral ml-1 my-4 w-full h-auto`}
          />
          {props.badge && (
            <Tag
              className={`absolute top-2 left-0 px-3  rounded-full text-sm font-medium`}
            >
              {props.badge.text}
            </Tag>
          )}
        </span>
        <div className="flex flex-col text-center mt-4">
          <p className="font-bold text-lg">{props.title}</p>
          <p className="break-normal">{props.desc}</p>
        </div>
        {props.button && (
          <Link
            to={props.button.url}
            onClick={() => window.scrollTo(0, 0)}
            className="bg-primary-darker rounded-full min-w-full text-white py-4 px-3 mt-4 text-center hover:bg-primary"
          >
            {props.button?.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Card;
