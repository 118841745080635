import React from 'react';
import { Formik, Form, Field } from 'formik';
import SelectField, { SelectOptions } from 'Components/Formik/SelectField';
import { useEffect } from 'react';
import { useState } from 'react';
import { getCompany } from 'utils/api/company';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import SelectMultiField from './Formik/SelectMultiField';
import { createUserSchema } from 'utils/validators/createUser';
import { requestInvite } from 'utils/api/invites';
import { toast } from 'react-toastify';
import { listMeritMoneySchemeByCompany } from 'utils/api/meritMoneyScheme';

interface AddUserProps {
  cancel: () => void;
}

const AddUser: React.FC<AddUserProps> = ({ cancel }) => {
  const { user } = useContext(UserContext);

  const [groupsOptions, setGroupsOptions] = useState<SelectOptions>([]);
  const [meritMoneySchemeOptions, setMeritMoneySchemeOptions] =
    useState<SelectOptions>([]);

  useEffect(() => {
    //get company groups
    if (!user?.companyId) return;
    getCompany(user?.companyId).then((company) => {
      const groups = company.groups;
      if (groups === undefined) return setGroupsOptions([]);
      const options = groups.map((group) => ({
        label: group.name,
        value: group._id,
      }));
      setGroupsOptions(options);
    });

    listMeritMoneySchemeByCompany(user.companyId).then(
      (meritMoneySchemeList) => {
        const options = meritMoneySchemeList.map((meritMoneyScheme) => ({
          label: meritMoneyScheme.name,
          value: meritMoneyScheme.id,
        }));
        setMeritMoneySchemeOptions(options);
      }
    );
  }, [user]);

  const initialValues = {
    name: '',
    email: '',
    meritMoneySchemeId: '',
    notAInvite: false,
    groups: [] as SelectOptions,
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { email, groups, meritMoneySchemeId, name, notAInvite } = values;
    const processedValues = {
      email,
      companyId: user.companyId,
      groups: groups.map((x) => x.value),
      meritMoneySchemeId,
      notAInvite,
      name,
    };

    console.log(processedValues);

    const promise = requestInvite(processedValues);
    toast.promise(promise, {
      pending: 'Cadastrando usuário',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel();
          return 'Usuário cadastrado.';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded w-11/12 md:w-5/12">
      <Formik
        validationSchema={createUserSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Adicione um usuário</p>
            <p className="mt-8">Nome:</p>
            <InputText name="name" placeholder="Digite o nome do usuário" />
            <p className="mt-8">E-mail:</p>
            <InputText name="email" placeholder="Digite o email do usuário" />
            <p className="mt-8">Esquema de merit money:</p>
            <SelectField
              name="meritMoneySchemeId"
              placeholder="Selecione um esquema"
              options={meritMoneySchemeOptions}
              className="mt-2"
            />
            <p className="mt-8">Grupos:</p>
            <SelectMultiField
              name="groups"
              options={groupsOptions}
              className="mt-2"
            />
            <label className="">
              <Field
                type="checkbox"
                name="notAInvite"
                className="mr-2 mt-2"
              />
              Já cadastrar
            </label>
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-between mt-4">
              {/* <button className="text-primary-darker">Salvar rascunho</button> */}
              <div></div>
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={cancel}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Adicionar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUser;
