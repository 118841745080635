import React, { useState, useContext, useCallback } from 'react';
import Navbar from 'Components/Navbar';
import {
  ProductDisplayModel,
  useSWRListCompanyDisplayProducts,
} from 'utils/api/productsDisplay';
import { UserModel } from 'utils/api/users';
import { UserDetailsTyoe } from 'Components/UserDetails';
import { UserContext } from 'Components/Context/UserContext';
import TableComponent, {
  TableComponentColumns,
} from 'Components/TableComponent';
import PurchaseTable from 'Pages/Products/Purchase';

interface ProductsListProps {
  selectProductsDetails: (
    user: UserModel,
    type?: UserDetailsTyoe,
    then?: (selectedProducts: ProductDisplayModel) => {}
  ) => void;
}

const ProductsTable: React.FC<ProductsListProps> = () => {
  const [tab, setTab] = useState('produtos');
  const perPage = 15;

  const { user } = useContext(UserContext);
  const [resetPageToggle, setResetPageToggle] = useState(false);

  const [renderKey, setRenderKey] = useState(0);
  const [page, setPage] = useState(1);
  const { data, metadata, isLoading } = useSWRListCompanyDisplayProducts(
    user?.companyId,
    {
      offset: (page - 1) * perPage || 0,
      limit: perPage,
    },
    renderKey
  );

  const columns = React.useMemo<TableComponentColumns>(
    () => [
      {
        id: 'name',
        name: 'Nome',
        selector: (row) => row.name,
        reorder: true,
        grow: 1,
      },
      {
        id: 'desc',
        name: 'Descrição',
        selector: (row) => row.desc,
        reorder: true,
        omit: true,
        grow: 2,
      },
      {
        id: 'minMeritus',
        name: 'Meritus minimo',
        selector: (row) => row.minMeritus,
        reorder: true,
        right: true,
      },
      {
        id: 'minPrice',
        name: 'Preço R$ minimo',
        selector: (row) => (row.minPrice / 100).toFixed(2),
        reorder: true,
        right: true,
      },
      {
        id: 'maxMeritus',
        name: 'Meritus maximo',
        selector: (row) => row.maxMeritus,
        reorder: true,
        right: true,
      },
      {
        id: 'maxPrice',
        name: 'Preço R$ máximo',
        selector: (row) => (row.maxPrice / 100).toFixed(2),
        reorder: true,
        right: true,
      },
      {
        id: 'variation',
        name: 'Variações',
        selector: (row) => row.variants.length,
        reorder: true,
        right: true,
      },
      {
        id: 'categories',
        name: 'Categorias',
        selector: (row) => row.categories,
        reorder: true,
        omit: false,
        grow: 2,
        cell: (row) => (
          <div className="flex flex-row gap-1 flex-wrap">
            {row.categories
              ? row.categories.map((categorie: any) => (
                  <p className="bg-neutral-darker rounded-full px-2">
                    {categorie}
                  </p>
                ))
              : undefined}
          </div>
        ),
      },
      {
        id: 'brand',
        name: 'Marca',
        selector: (row) => row.brand,
        reorder: true,
        omit: false,
      },
    ],
    []
  );

  const onChangePage = useCallback(async (page: number) => {
    setPage(page);
  }, []);

  return (
    <>
      <Navbar />
      <div className="px-10 mt-7">
        <p className="text-xs">
          <span className="text-primary">Produtos</span>
        </p>
        <div className="flex items-center justify-between">
          <p className="text-3xl mt-4 text-black">Produtos</p>
        </div>
        <div className="mt-12 md:mt-16 shadow px-1 md:px-7 pb-7">
          <div className="flex gap-4 border-b border-lighter-70 py-1 px-3 mb-5 mt-2">
            <button
              className={`${
                tab === 'produtos' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('produtos')}
            >
              Produtos
            </button>
            <button
              className={`${
                tab === 'compras' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('compras')}
            >
              Compras
            </button>
          </div>
          {tab === 'produtos' ? (
            <div className="mt-16 shadow px-7 pb-7">
              <TableComponent
                className="w-full"
                data={data}
                columns={columns}
                isLoading={isLoading}
                pagination
                paginationServer
                paginationTotalRows={metadata?.total}
                onChangePage={onChangePage}
                perPage={perPage}
                resetPageToggle={resetPageToggle}
              />
            </div>
          ) : (
            <PurchaseTable />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsTable;
