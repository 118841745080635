import React from 'react';
import { ReactComponent as ArrowLeft } from 'assets/vectors/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/vectors/arrowRight.svg';

const MAX_ITEMS = 3;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

type PaginationProps = {
  limit?: number;
  total?: number;
  offset?: number;
  isLoading?: boolean;
  setOffset: (x: number) => void;
};
const Pagination: React.FC<PaginationProps> = ({
  limit,
  total,
  offset,
  setOffset,
  isLoading,
}) => {
  let currentPage = offset && limit ? offset / limit + 1 : 1;
  const pages = total && limit ? Math.ceil(total / limit) : 1;
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
  const firstPage = Math.min(Math.max(currentPage - MAX_LEFT, 1), maxFirst);

  const onPageChange = (page: number) => {
    if (limit) {
      setOffset((page - 1) * limit);
    }
  };

  return (
    <ul className="flex list-none">
      <li>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 text-xl disabled:opacity-20"
        >
          <ArrowLeft />
        </button>
      </li>
      {Array.from({ length: Math.min(MAX_ITEMS, pages) })
        .map((_, index) => index + firstPage)
        .map((page) => (
          <li className="ml-5" key={page}>
            <button
              className={
                page === currentPage
                  ? 'font-bold px-3 py-1 border rounded-full bg-primary-darker text-white'
                  : 'px-3 py-1'
              }
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      <li>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pages}
          className="px-3 py-1 ml-5 text-xl disabled:opacity-20"
        >
          <ArrowRight />
        </button>
      </li>
    </ul>
  );
};
export default Pagination;
