import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

export const RequestPasswordRecoverySchema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .noUnknown(true);

export const FinishPasswordRecoverySchema = yup
  .object()
  .shape({
    password: yup.string().required(),
    confirm: yup
      .string()
      .test(
        'passwords-match',
        'Confirmção de senha invalida',
        function (value) {
          return this.parent.password === value;
        }
      )
      .required(),
  })
  .noUnknown(true);
