import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

enum ApproveType {
    SIMPLE = 'simple',
    PROOF = 'proof',
  }
yup.setLocale(ptShort);

export const updateMissionSchema = yup.object().shape({
    name: yup.string().required(),
    groups: yup.array().min(1).required(),
    companyValues: yup.array().min(1).required(),
    meritus:yup.number().required(),
    description:yup.string().required(),
    startsAt:yup.date(),
    endsAt:yup.date().min(yup.ref('startsAt')),
    approveType: yup.string().oneOf(Object.values(ApproveType)).required(),
});