import React, { useState } from 'react';

import { ErrorMessage, Field, FieldProps } from 'formik';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './DateSelector.css';
import DatePicker, {
  Day,
  DayValue,
  Locale,
} from '@amir04lm26/react-modern-calendar-date-picker';
// no working on react v17 import DatePicker, {
//   DayRange,
//   Locale,
// } from 'react-modern-calendar-datepicker';

const customLocale = {
  // months list by order
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'D', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Monday',
      short: 'S',
    },
    {
      name: 'Tuesday',
      short: 'T',
    },
    {
      name: 'Wednesday',
      short: 'Q',
    },
    {
      name: 'Thursday',
      short: 'Q',
    },
    {
      name: 'Friday',
      short: 'S',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
} as Locale;

const convertToDateObject = (date: Date | null | undefined): Day | undefined => {
  console.log('date', date, typeof date);
  
  if (!date) return undefined;
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
};

interface DateSelectorProps {
  name: string;
  className?: string;
  initialValue?: Date;
  onChangeCallback?: () => void;
  isDisabled?: boolean;
  calendarPopperPosition?: 'bottom';
  maximumDate?: Date;
  minimumDate?: Date;
}
const DateSelector: React.FC<DateSelectorProps> = (props) => {
  return <Field component={DateSelectorChild} {...props} />;
};

interface DateSelectorChildProps extends FieldProps, DateSelectorProps {}

const DateSelectorChild: React.FC<DateSelectorChildProps> = ({
  className,
  field,
  form,
  initialValue = null,
  meta,
  onChangeCallback,
  calendarPopperPosition,
  maximumDate,
  minimumDate= new Date(),
}) => {
  const handleOnChange = (date: DayValue) => {
    const processedDate = date
      ? new Date(date.year, date.month - 1, date.day)
      : null;

    form.setFieldValue(field.name, processedDate);

    if (onChangeCallback && date) {
      onChangeCallback();
    }
  };

  const handleValue = (date: Date) => {
    if (!date) return initialValue ? convertToDateObject(initialValue) : null;

    const processedDate = date ? convertToDateObject(date) : null;
    return processedDate;
  };

  const resetValues = () => {
    form.setFieldValue(field.name, null);
  };

  return (
    <div className={`${className} relative`}>
      <DatePicker
        inputClassName="text-base text-left"
        inputPlaceholder="Data"
        colorPrimary="#3200D9"
        colorPrimaryLight="#DFE2FD" // change to meritu colors
        calendarClassName="responsive-calendar"
        shouldHighlightWeekends
        value={handleValue(field.value)}
        onChange={handleOnChange}
        locale={customLocale}
        renderFooter={() => DateSelectorFooter({ resetValues })}
        calendarPopperPosition='bottom'
        maximumDate={convertToDateObject(maximumDate)}
        minimumDate={convertToDateObject(minimumDate)}
      />
      <p className="text-xs text-red absolute font-bold">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

const DateSelectorFooter: React.FC<{ resetValues: () => void }> = ({
  resetValues,
}) => (
  <div className="w-full pb-2 px-4 flex justify-end">
    <button
      className="text-sm px-3 py-2 bg-primary rounded-input text-white cursor"
      onClick={resetValues}
    >
      Limpar
    </button>
  </div>
);

export default DateSelector;
