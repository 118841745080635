import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from 'Components/Context/UserContext';
import { listInvite, requestInvite, UserInviteModel } from 'utils/api/invites';
import { toast } from 'react-toastify';

interface InvitesProps {
  setAndShowUpdateInvite: (invite: UserInviteModel) => void;
  setAndShowInvalidateInvite: (invite: UserInviteModel) => void;
}

const Invites: React.FC<InvitesProps> = ({
  setAndShowUpdateInvite,
  setAndShowInvalidateInvite,
}) => {
  const [invites, setInvites] = useState<UserInviteModel[] | undefined>();
  const [invitingArray, setInvitingArray] = useState<string[]>([]);
  const { user } = useContext(UserContext);

  const translateStatus = (status: string) => {
    switch (status) {
      case 'complete':
        return 'Completo';
      case 'pending':
        return 'Pendente';
      case 'expired':
        return 'expirado';
      case 'resending':
        return 'Reenviado';
      case 'invalidated':
        return 'Deletado';
    }
  };

  const resendInvite = (invite: UserInviteModel) => {
    setInvitingArray([...invitingArray, invite.id]);
    const { companyId, email, groups, meritMoneySchemeId, name } = invite;
    const promise = requestInvite({
      companyId,
      email,
      groups,
      meritMoneySchemeId,
      name,
    });
    toast
      .promise(promise, {
        error: 'Nào foi possivel reenviar o convite, tente mais tarde',
        pending: 'Reenviando convite',
        success: {
          render: () => {
            const oldInviteIndex = invites?.findIndex(
              (x) => x.id === invite.id
            );

            if (!invites) return {};
            if (oldInviteIndex === undefined) return {};

            const newInviteList = [...invites];
            newInviteList[oldInviteIndex].status = 'resending';
            newInviteList[oldInviteIndex].createdAt = new Date();

            setInvites(newInviteList);
            return 'Convite reenviado com sucesso';
          },
        },
      })
      .finally(() =>
        setInvitingArray([...invitingArray.filter((x) => x !== invite.id)])
      );
  };

  useEffect(() => {
    (async () => {
      if (user?.companyId === undefined) return console.log('deu ruim');
      const invites = await listInvite(user.companyId);
      setInvites(invites);
    })();
  }, [user?.companyId]);

  return (
    <>
      <p className="text-xl text-black">Lista de Convites</p>
      {!invites ? (
        <p>Loading...</p>
      ) : !invites.length ? (
        <p className="ml-2">Parece que não há convites pendentes</p>
      ) : (
        <div className="md:w-full gap-2 mt-3">
          {invites.map((invite) => {
            return (
              <div
                key={invite.id}
                className="flex flex-col flex-wrap md:flex-row justify-between md:items-center mt-3 md:p-3 bg-lighter-20 rounded w-full"
              >
                <div className="md:ml-3 p-2">
                  <p className='text-sm md:text-base'>Nome: {invite.name}</p>
                  <p className='text-sm md:text-base'>Email: {invite.email}</p>
                  <p className='text-sm md:text-base'>Status: {translateStatus(invite.status)}</p>
                  <p className='text-sm md:text-base'>
                    Data: {new Date(invite.createdAt).toLocaleString('pt-br')}
                  </p>
                  <p className='text-sm md:text-base'>
                    Link:{' '}
                    <p id='link'>{`https://app.meritu.co/finishRegistration/${invite.id}`}</p>
                  </p>
                </div>
                <div className="flex md:gap-4 gap-2 p-2">
                  {invite.status !== 'complete' &&
                    invite.status !== 'resending' &&
                    invite.status !== 'invalidated' && (
                      <>
                        <button
                          className= "mr-2 border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary text-sm md:text-base"
                          onClick={() => resendInvite(invite)}
                          disabled={invitingArray.includes(invite.id)}
                        >
                          Reenviar
                        </button>
                        <button
                          className="mr-2 border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary text-sm md:text-base"
                          onClick={() => setAndShowUpdateInvite(invite)}
                        >
                          Editar
                        </button>
                        <button
                          className="border border-primary-darker p-1 rounded-input text-primary-darker hover:border-primary hover:text-primary text-sm md:text-base"
                          onClick={() => setAndShowInvalidateInvite(invite)}
                        >
                          Deletar
                        </button>
                      </>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Invites;
