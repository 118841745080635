import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { i18n } from 'utils/i18n/index';

import {
  listProductDisplay,
  ProductDisplayModel,
} from 'utils/api/productsDisplay';

import Navbar from 'Components/Navbar';
import AutoMargin from 'Components/AutoMargin';
import Card, { CardProps } from 'Components/Card';
import { UserContext } from 'Components/Context/UserContext';
import StoreFilters from 'Components/Filters/StoreFilter';
import Pagination from 'Components/Pagination';

// import { ReactComponent as SearchIcon } from '../../assets/vectors/search.svg';
import { ReactComponent as FilterIcon } from 'assets/vectors/filter.svg';
import { ReactComponent as ArrowUp } from 'assets/vectors/arrowUp.svg';
import { ReactComponent as ArrowDown } from 'assets/vectors/arrowDown.svg';

interface StoreProps {}

const Store: React.FC<StoreProps> = () => {
  const perPage = 12;

  const { user } = useContext(UserContext);

  const [products, setProducts] = useState<ProductDisplayModel[] | undefined>();
  const [totalProducts, setTotalProducts] = useState(0);
  const [reRender, setRerenderer] = useState<boolean>(false);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSetShowFilters = () => {
    setShowFilters(!showFilters);
  };
  useEffect(() => {
    (async () => {
      if (!user?.companyId) return new Error('deu ruim');

      setIsLoading(true);

      const query = {
        limit: perPage,
        offset,
        ...filters,
      };

      try {
        const products = await listProductDisplay(user.companyId, query);
        setTotalProducts(products.metadata.total || 0);
        setProducts(products.data || []);
        setRerenderer(false);
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    })();
  }, [user, reRender, filters, offset]);

  const handleFilters = async (filter: Record<any, any>) => {
    setFilters(filter);
    setOffset(0);
    setRerenderer(true);
  };

  return (
    <div className="text-sm lg:text-base">
      <Navbar />
      <AutoMargin className="w-full mt-4 p-2 lg:mt-7 max-w-7xl">
        <div className="px-2 lg:px-10">
          <p className="text-xs">
            DASHBOARD /{' '}
            <span className="text-primary">{i18n.t('storeMenu')}</span>
          </p>
          <div className="flex items-baseline justify-between">
            <p className="text-xl lg:text-3xl mt-4 text-black">
              {i18n.t('store')}
            </p>
            {/* <Link
              to="/colaborador/trocas"
              className="border border-primary-darker p-1 lg:p-2 rounded-input text-primary-darker hover:border-primary hover:text-primary"
            >
              {i18n.t('myExchanges')}
            </Link> */}
          </div>
          <div className="mt-5 flex flex-col bg-neutral shadow rounded-input px-5">
            <div
              className="flex items-center px-2 py-2 w-full cursor-pointer justify-between"
              onClick={handleSetShowFilters}
            >
              <div className="flex">
                <FilterIcon className="w-3 mr-2 cursor-pointer text-darker-50 stroke-current hover:text-black" />
                {showFilters
                  ? `${i18n.t('HideFilters')}`
                  : `${i18n.t('showFilters')}`}
              </div>
              <div>{showFilters ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
            {showFilters === true && (
              <StoreFilters
                handleFilters={handleFilters}
                isReRendering={reRender}
              />
            )}
          </div>
          <div className="mt-4 shadow p-4 lg:p-7">
            <p className="text-xl text-black">
              {i18n.t('listProductsServices')}
            </p>
            {!products || isLoading ? (
              <p>{i18n.t('loading')}</p>
            ) : !products.length ? (
              <p className="ml-2">{i18n.t('noProducts')}</p>
            ) : (
              <div className="grid grid-cols-test w-full gap-2 mt-3">
                {products.map((value) => {
                  const attr = {
                    image: { src: value.images[0] },
                    title: value?.name,
                    button: {
                      text: `${i18n.t('knowMore')}`,
                      url: `/loja/${value.id}`,
                    },
                    badge: {
                      text:
                        value.variants.length <= 1
                          ? `R$ ${(value.minPrice / 100).toFixed(2)}`
                          : `apartir de R$ ${(value.minPrice / 100).toFixed(2)}`,
                    },
                  } as CardProps;
                  return <Card key={value.id} {...attr} />;
                })}
              </div>
            )}
            <div className="flex justify-end mt-10">
              <Pagination
                limit={perPage}
                total={totalProducts}
                offset={offset}
                setOffset={setOffset}
              />
            </div>
          </div>
        </div>
      </AutoMargin>
    </div>
  );
};

export default Store;
