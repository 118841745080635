import AutoMargin from 'Components/AutoMargin';
import { UserContext } from 'Components/Context/UserContext';
import { FieldArray, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import history from 'utils/history';
import { RequetManagerPurchaseSchema } from 'utils/validators/purchase';
import { i18n } from 'utils/i18n/index';
import { ProductVariantModel } from 'utils/api/productsDisplay';
import SelectUserField from 'Components/Formik/SelectUserField';
import { SelectOptions } from 'Components/Formik/SelectField';
import { listDisplayName } from 'utils/api/users';
import { requestManagerPurchase } from 'utils/api/purchase';

interface ConfirmPurchaseProps {
  product: ProductVariantModel | undefined | null;
  close: () => void;
}

const ConfirmPurchase: React.FC<ConfirmPurchaseProps> = ({
  close,
  product,
}) => {
  const { user, updateUserContext } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [userOptions, setUserOptions] = useState<SelectOptions>([]);

  useEffect(() => {
    if (user?.companyId === undefined) return;
    listDisplayName().then((users) => {
      const processed = users.map((user) => ({
        label: user.displayName,
        value: user.id,
        ...user,
      }));
      setUserOptions(processed);
      setLoading(false);
    });
  }, [user]);

  const initialValues = {
    selectedUser: [''],
  };

  if (!product || !user || !user.meritus)
    return (
      <AutoMargin className="flex-grow bg-white rounded w-full p-10">
        {i18n.t('somethingWrong')}
        <button className="rounded-full px-8 py-1 border" onClick={close}>
          {i18n.t('cancel')}
        </button>
      </AutoMargin>
    );

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    actions.setSubmitting(true);

    const quantity = values.selectedUser.reduce(
      (prev, val) => (val === '' ? prev : prev + 1),
      0
    );

    const promise = requestManagerPurchase({
      productId: product.id,
      users: values.selectedUser,//values.users,
      value: (product.realPrice + product.spreadRealValue) * quantity
    });

    toast.promise(promise, {
      pending: i18n.t('makingPurchase') || 'makingPurchase',
      error: {
        render: () => {
          actions.setSubmitting(false);
          return i18n.t('somethingWrong');
        },
      },
      success: {
        render: () => {
          actions.setSubmitting(false);
          updateUserContext();
          history.push('/loja');
          return i18n.t('purchaseMade');
        },
      },
    });
  };

  return (
    <AutoMargin className="flex-grow bg-white rounded lg:w-5/12 w-full p-3 lg:p-8 min-w-128">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={RequetManagerPurchaseSchema}
      >
        {({ isSubmitting, submitForm, values }) => {
          const quantity = values.selectedUser.reduce(
            (prev, val) => (val === '' ? prev : prev + 1),
            0
          );
          const value = (product.realPrice / 100) * quantity;
          const spread = (product.spreadRealValue / 100) * quantity;
          return (
            <>
              <p className="text-black text-xl">
                {i18n.t('Presenteie pessoas')}
              </p>
              <p></p>
              {/* <p className="text-sm font-bold text-red">{error}</p> */}
              <FieldArray name="selectedUser">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { selectedUser } = values;
                  return (
                    <div className="w-full">
                      {selectedUser.map((user: any, index: number) => (
                        <div
                          className="flex items-center mt-2 gap-3"
                          key={index}
                        >
                          <SelectUserField
                            name={`selectedUser[${index}]`}
                            placeholder={i18n.t('selectPerson')}
                            options={userOptions.filter((user) => {
                              if (user.value === selectedUser[index]) {
                                return true;
                              }
                              return !selectedUser.includes(user.value);
                            })}
                            className="w-full"
                            isLoading={loading}
                            noOptionsMessage={i18n.t('noOptions')}
                            isDisabled={index < selectedUser.length - 1}
                          />

                          <div>
                            {selectedUser[index] !== '' &&
                            index >= selectedUser.length - 1 ? (
                              <button
                                className="text-base font-bold border rounded-full px-1.5 text-center justify-center"
                                type="button"
                                onClick={() => {
                                  push('');
                                  console.log(selectedUser, index);
                                }}
                              >
                                +
                              </button>
                            ) : selectedUser[index] === '' ? (
                              <div className="text-base font-bold invisible rounded-full px-1.5 text-center justify-center">
                                {'+'}
                              </div>
                            ) : (
                              <button
                                className="text-base font-bold border rounded-full px-2 text-center justify-center"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                -
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                }}
              </FieldArray>
              <p className="mt-5">{i18n.t('revewYourOrderAndFinalize')}</p>
              <div className="justify-around w-full gap-6 mt-3 p-2">
                <label className="flex flex-col w-full">
                  {`${i18n.t('unitaryValue')} R$ ${(
                    product.realPrice / 100
                  ).toFixed(2)}`}
                </label>
                <label className="flex flex-col w-full">
                  {`${i18n.t('Quantidade')}: ${quantity}`}
                </label>
                <label className="flex flex-col w-full">
                  {`${i18n.t('Valor')} R$ ${value.toFixed(2)}`}
                </label>
                <label className="flex flex-col w-full">
                  {`${i18n.t('Taxa')} R$ ${spread.toFixed(2)}`}
                </label>
              </div>
              <label className="flex mt-3 flex-col w-full font-bold">
                {`${i18n.t('Total')} R$ ${(value + spread).toFixed(2)}`}
              </label>
              {/* <div className="rounded bg-neutral shadow p-5 mt-3 whitespace-nowrap grid grid-cols-3 gap-x-3 gap-y-2"> */}

              <div className="flex mt-7 justify-end gap-5 w-full">
                <button
                  className="rounded-full px-8 py-1 border"
                  onClick={close}
                >
                  {i18n.t('cancel')}
                </button>
                <button
                  className="rounded-full bg-primary-darker px-8 py-1 text-white disabled:opacity-30"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {i18n.t('finish')}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </AutoMargin>
  );
};

export default ConfirmPurchase;
