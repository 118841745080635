export const messages = {   
        ptBR:{
            translations: {
                "boostText": "Impulsionando",
                "theValue": "o valor",
                "ofConnections": "das conexões",
                "joinThePlatform": "Entre na plataforma e descubra um mundo mais colaborativo",
                "accessYourAccount": "Acesse sua conta",
                "email": "Email",
                "typeYourEmail": "Digite seu email",
                "password": "Senha",
                "typeYourPassword": "Digite sua senha",
                "forgotPassword": "Esqueci a senha",
                "login": "Entrar",
                "or": "ou",
                "loginWith": "Entrar com",
                "invalidEmailPassword": "Email ou senha invalido",
                "somethingWrong":"Algo deu errado",
                "recognizeColleague": "Reconheça um colega!",
                "toRecognizeCollegues": "para reconhecer seus colegas",
                "loading": "Carregando...",
                "noFeedBacks": "Parece que não há reconhecimentos no momento",
                "youSawEverything": "Boa! Você viu tudo que tinha!",
                "pullDownToUpdate": "Puxe para baixo para atualizar",
                "releaseToUpdate" : "Solte para atualizar",
                "completedTheMission": " completou a missão",
                "Logout": "Sair",
                "myExchanges": "Minhas trocas",
                "listProductsServices": "Lista de produtos e serviços",
                "noProducts": "Parece que não há produtos no momento",
                "knowMore": "Saber mais",
                "storeMenu": "LOJA",
                "store": "Loja",
                "whereWantGo": "Onde você deseja ir?",
                "mission": "Missão",
                "profile": "Perfil",
                "attachFile": " Anexe um arquivo que demonstre que você completou a missão, pode ser um print de tela, um vídeo curto, arquivo de texto ou qualquer outro arquivo que mostre que você completou a missão para seus colegas :)",
                "mustAttachFile": "*Deve anexar um arquivo",
                "missionMenu": "MISSÃO",
                "missions": "Missões",
                "noMissions": "Parece que não há missões no momento",
                "expired": "Expirou:",
                "start": "Começa:",
                "expires": "Expira:",
                "recoverPassword": "Recupere sua senha",
                "enterNewPassword": "Digite sua nova senha para acessar a plataforma",
                "registerNewPassword": "Cadastrando nova senha",
                "passwordRequestExpired": "Pedido de recuperação de senha expirado",
                "typeNewPassword": "Digite sua nova senha para acessar a plataforma",
                "passwordRegisterSuccessfully": "Senha cadastrada com sucesso",
                "yourNewPassword": "Digite sua nova senha",
                "repeatYourNewPassword": "Repita sua nova senha",
                "registerPassword": "Cadastrar senha",
                "cancel": "Cancelar",
                "error": "erro",
                "confirmation": "Confirmação:",
                "quantity": "Quantidade",
                "typeQuantity": "Digite a quantidade",
                "comments": "Observações",
                "typeComments": "Digite as observações",
                "exchangeMeritus": "Trocar meritus",
                "categories": "Categorias",
                "description": "Descrição",
                "seeOtherProducts":"Veja outros produtos",
                "myExchangesTitle": "MINHAS TROCAS",
                "noExchangeScore": "Parece que você não trocou seus pontos ainda",
                "meritusSpent": "Meritus gastos",
                "observation": "Observação:",
                "date": "Data:",
                "status": "Status:",
                "updatingProfile": "Atualizando perfil",
                "updatedProfile": "Perfil atualizado.",
                "editProfile": "Editar Perfil",
                "changeTheFieldsYouWantToUpdade": "Altere os campos que deseja atualizar, não é necessário que todos os campos sejam preenchidos",
                "nickname/name": "Apelido / Nome:",
                "profilePicture": "Imagem de perfil",
                "tellMeMoreAboutYou": "Fale um pouco sobre você",
                "newPassword": "Nova senha",
                "typeTheNewPassword": "Digite a nova senha",
                "repeatPassword": "Repita a senha",
                "typeAgain": "Digite novamente",
                "secondaryEmail": "Email secundário",
                "typeYourSecondaryEmail": "Digite seu email secundário",
                "cellphone": "Celular",
                "typeCellphone": "Digite o celular",
                "change": "Alterar",
                "typeNickname": "Digite um apelido",
                "completedMission": " completou a missão",
                "recognizedBy": " foi reconhecido(a) por ",
                "done": "Feito!",
                "missionHasnotStarted": 'Missão não começou!',
                "finish": 'Finalizar',
                "participate": 'Participar',
                "company": "Empresa",
                "profileMenu": "PERFIL",
                "myProfile": "Meu Perfil",
                "profileOf": "Perfil de ",
                "editProfileButton": "Editar perfil",
                "changePassword": "Alterar senha",
                "timeline": "Timeline de reconhecimentos",
                "changingPassword": "Alterando senha.",
                "passwordChanged": "Senha alterada.",
                "fillTheInput": "Por favor, preencha os seguintes campos de entrada para alterar sua senha.",
                "companyMenu": "EMPRESA",
                "values": "Valores",
                "wallet": "Carteira",
                "toBeDistributed": "A distribuir:",
                "enterYourEmailToRecoverPassword": "Digite seu email para iniciar o processo de recuperação de senha",
                "sendingPasswordRecoveryEmail": "Enviando email de recuperação de senha",
                "emailSent": "Email enviado",
                "TryAgainLater": "Falha, tente novamente mais tarde",
                "recoverPasswordButton": "Recuperar Senha",
                "recognizeYourCollegues": "Reconheça seus colegas!",
                "youHave": "Você tem",
                "creditsToDistribute": "créditos para distribuir",
                "selectPerson": "Selecione",
                "value": "Valor",
                "thisRecognitionIsRelatedTo":"Este reconhecimento está relacionado à:",
                "message": "Mensagem",
                "recognize": "Reconhecer",
                "typeMessage": "Digite uma mensagem",
                "searchTenor": "Pesquisar Tenor",
                "search": "Pesquisar",
                "enjoy": "Curtir",
                "hide": "Esconder",
                "comment": "Comentar",
                "writeComment": "Escreva um comentário...",
                "itdidnotWork": "Deu Ruim",
                "expiredMission": "Expirado!",
                "noImagesToShow": "Sem imagens para mostrar",
                "readLess": "Leia menos",
                "readMore": "Leia mais",
                "doSearch": "Faça uma pesquisa",
                "credits" : "créditos",
                "dateLocaleString": "pt-BR",
                "recognizedYouFor": "te reconheceu por ",
                "seeAllComments": "Ver todos os comentários",
                "hideComments": "Esconder comentários",
                "pending": "Pendente",
                "accepted": "Aceito",
                "makingPurchase": "Realizando compra",
                "purchaseMade": "Compra realizada",
                "revewYourOrderAndFinalize": "Revise seu pedido e finalize a troca!",
                "unitaryValue": "Valor unitário:",
                "youWillSpend": "Paga",
                "remain": "Sobra",
                "insufficient": "Saldo insulficiente",
                "exPired": "Expirado",
                "finishingTheMission": "Finalizando a missão",
                "missionCompletedSuccessfully": "Missão finalizada com sucesso",
                "acceptingMission": "Aceitando missão",
                "missionAcceptedSuccessfully": "Missão aceita com sucesso",
                "noOptions": "Não há opcões",
                "showFilters": "Mostrar Filtros",
                "HideFilters": "Ocultar Filtros",
                "companyValues": "Valores da empresa",
                "minimumPointValue": "Valor mínimo de pontos",
                "minimumPoints": "Valor mínimo",
                'maximumPointValue': "Valor máximo de pontos",
                "maximumValue": "Valor máximo",
                "missionStatus": "Status da missão",
                "missionStartPeriod": "Período de inicio da missão",
                "missionExpirationTime": "Período de expiração da missão",
                "clean": "Limpar",
                "inProgress": "Em andamento",
                'notAccepted': 'Não aceitas',
                'whoWasRecognized': "Quem foi reconhecido",
                "whoRecognized": "Quem reconheceu",
                "whenRecognized": "Quando reconheceram",
                "transactionType": "Tipo da transação",
                "selection": "Escolha",
                "productName": "Nome do produto",
                "name": "Nome",
                "datePicker": "Data",
                "notEnoughCredits": "Sem meritus o suficiente",
                "have":"Tem",
                "completed": "Completo",
                "howMuchToDistribute": "Quantidade de meritus que deseja distribuir para cada colegas",
                "totalToDistribute": "Quantidade total a ser distribuida",
                "Click+ToRecognize": "Clicando no + você pode reconhecer vários colegas!",
                'termsOfUse': 'Termo de uso',
                'toAccess': "Para aceesar o sistema meritu é necessário aceitar nosso termos de uso",
                'accept': "Aceitar",
                'haveReadThe': 'Eu li e aceito os',
                'termsOfService': 'termos de uso',
                'unableAcceptTerms': 'Não foi possivel aceitar os termos de serviço',
                "EditProfile": "Editar perfil",
                "ChangePassword": "Alterar senha",
                "ProcessingRecognition": "Processando reconhecimento",
                "RecognitionWasSuccessful": "Reconhecimentos feitos com sucesso",
                "General": "Geral",
                "Wallets": "Carteiras",
                "Alimentação": "Alimentação",
                "Lazer": "Lazer",
                "Escritório": "Escritório",
                "Treinamento": "Treinamento",
                'generatingImageToShare': 'Gerando imagem de compartilhamento',
                'shareCompleted': 'Imagem gerada',
                'from': 'a partir de',
                'Recognition': 'Reconhecimento',
            }
        }
}