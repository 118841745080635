import { ErrorMessage, Field, FieldProps } from 'formik';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import React from 'react';
import { Option } from 'react-select/src/filters';

export type SelectOptions = OptionsType<OptionTypeBase>;

interface SelectFieldProps {
  name: string;
  options: SelectOptions;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (option: Option) => void;
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  return <Field component={SelectChild} {...props} />;
};

const selectStyle = {
  control: (base: {}) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    indicatorSeparator: { display: 'none' },
  }),
};

interface SelectChildProps extends FieldProps, SelectFieldProps {}

const SelectChild: React.FC<SelectChildProps> = ({
  field,
  form,
  meta,
  options,
  className,
  placeholder,
  disabled,
  onChange,
}) => {
  return (
    <div className={`${className} relative bg-white`}>
      <Select
        isDisabled={disabled}
        options={options}
        name={field.name}
        className="px-2 h-10 border border-lighter-70 rounded-input focus:outline-none"
        styles={selectStyle}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
        onChange={(option: Option) => {
          form.setFieldValue(field.name, option.value);
          onChange && onChange(option)
        }}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
      <p className="text-xs text-red absolute">
        <ErrorMessage name={field.name} />
      </p>
    </div>
  );
};

export default SelectField;
