import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

export const reportSchema = yup.object().shape({
    reportType: yup.string().required(),
    dateMin: yup.date().required(),
    dateMax: yup.date().min(
        yup.ref('dateMin'),
        "Deve ser depois de Date inicial"
      ).required(),
});