import React, { useState, useEffect, useMemo } from 'react';
import DataTable, {
  createTheme,
  TableColumn,
} from 'react-data-table-component';
import Select, { OptionsType, OptionTypeBase, components } from 'react-select';

export interface TableComponentColumn<T> extends TableColumn<T> {
  id: string;
}
export type TableComponentColumns = TableComponentColumn<Record<any, any>>[];

interface TableComponentProps {
  title?: string;
  className?: string;
  columns: TableComponentColumns;
  data: Record<any, any>[] | undefined;
  isLoading?: boolean;
  selectableOmitColumns?: boolean;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationTotalRows?: number;
  onChangeRowsPerPage?: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onChangePage?: (page: number, totalRows: number) => void;
  perPage?: number;
  resetPageToggle?: boolean
}

const ProgressCompnent = () => {
  return <div className="text-sm text-darker-50 mt-3">Carregando....</div>;
};

const NoDataComponent = () => {
  return <div className="text-sm text-darker-50 mt-3">Sem dados :'(</div>;
};

createTheme(
  'meritu',
  {
    text: {
      primary: '#5a5776',
      // secondary: '#8EE267',
    },

    // background: {
    //   default: '#fffff',
    // },
    // context: {
    //   background: '#cb4b16',
    //   text: '#FFFFFF',
    // },
    divider: {
      default: '#F6F6F6',
    },
    highlightOnHover: {
      default: '#F3F6FC',
    },
    // sortFocus: {
    //   default: '#2aa198',
    // },
  },
  'light'
);

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }: any) => (
  <components.ValueContainer {...props}>
    <p className="px-2">Colunas</p>
    {children[1]}
  </components.ValueContainer>
);

const TableComponent: React.FC<TableComponentProps> = ({
  isLoading,
  columns,
  data,
  title,
  className,
  pagination,
  paginationServer,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  perPage,
  resetPageToggle,
}) => {
  const [selectOptions, setSelectOptions] =
    useState<OptionsType<OptionTypeBase>>();
  const [selectValues, setSelectValues] =
    useState<OptionsType<OptionTypeBase>>();
  const [omitedColumns, setOmitedColumns] = useState<
    Record<string, boolean> | undefined
  >();
  const [allTrueOmitedColumns, setAllTrueOmitedColumns] = useState<
    Record<string, boolean> | undefined
  >();

  useEffect(() => {
    // setting omitedColumns to values specified in columns an creating allTrueOmitedColumns for using to reset
    const omitedColumns = {} as Record<any, any>;
    const allTrueOmitedColumns = {} as Record<any, any>;
    columns.forEach((column) => {
      omitedColumns[column.id] = column.omit || false;
      allTrueOmitedColumns[column.id] = true;
    });
    setOmitedColumns(omitedColumns);
    setAllTrueOmitedColumns(allTrueOmitedColumns);

    // seting selectOptions
    const selectOptions = columns.map((column) => ({
      label: column.name,
      value: column.id,
    }));
    setSelectOptions(selectOptions);

    //seting selectValues
    const selectValues = [] as any[];
    columns.forEach((column) => {
      if (column?.omit === true) return;
      selectValues.push({
        label: column.name,
        value: column.id,
      });
    });
    setSelectValues(selectValues);
  }, [columns]);

  const columnsWithOmit = useMemo<TableComponentColumns>(() => {
    if (omitedColumns === undefined) return columns;
    const columnsWithOmit = columns.map((column) => {
      const aux = { ...column };
      aux.omit = omitedColumns[column.id] || false;
      return aux;
    });
    return columnsWithOmit;
  }, [columns, omitedColumns]);

  const handleSelectValueChange = (values: OptionsType<OptionTypeBase>) => {
    console.log('a', allTrueOmitedColumns);

    const clearOmitedColumn = { ...allTrueOmitedColumns };
    values.forEach((value) => {
      clearOmitedColumn[value.value] = false;
    });
    setOmitedColumns(clearOmitedColumn);
    setSelectValues(values);
  };

  return (
    <>
      <div className={className}>
        <div className="w-full flex justify-end p-2">
          <Select
            isMulti
            options={selectOptions}
            closeMenuOnSelect={false}
            isClearable={false}
            hideSelectedOptions={false}
            name="omitColumns"
            className="px-2 h-10 rounded-input focus:outline-none"
            components={{
              IndicatorSeparator: () => null,
              Option,
              ValueContainer,
            }}
            value={selectValues}
            onChange={handleSelectValueChange}
          />
        </div>
        <DataTable
          title={title}
          columns={columnsWithOmit}
          data={data || []}
          progressPending={isLoading || false}
          progressComponent={<ProgressCompnent />}
          noDataComponent={<NoDataComponent />}
          onColumnOrderChange={(cols) => cols}
          theme="meritu"
          responsive
          highlightOnHover
          persistTableHead
          pagination={pagination}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          // onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          paginationPerPage={perPage}
          paginationDefaultPage={1}
          paginationResetDefaultPage={resetPageToggle}
        />
      </div>
    </>
  );
};

export default TableComponent;
