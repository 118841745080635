import React from 'react'
import { Formik, Form } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import InputText from 'Components/Formik/InputText';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { toast } from 'react-toastify';
import { disableGroup, enableGroup, GroupModel } from 'utils/api/group';


interface GroupDetailsProps {
    selectedGroup: GroupModel | undefined ;
    cancel: (refresh?: boolean, newGroupDetails?: GroupModel) => void;
    type: GroupDetailsType;
    then: (selectedGroup: GroupModel) => void;
}


export enum GroupDetailsType {
    ENABLE = 'enable',
    DISABLE = 'disable',
    DETAIL = 'detail',
}

const GroupDetails:React.FC<GroupDetailsProps> = ({
    selectedGroup,
    cancel,
    type,
    then
    }) => {
    const { user } = useContext(UserContext);
    const [options, setOptions] = useState({
        extraInfo: true,
        showConfirm: false,
        text: 'Detalhes',
        buttonText: 'Detalhes',
        disableInput: true,
    });

   
  
    useEffect(() => {
        switch (type) {
          case GroupDetailsType.ENABLE:
            setOptions({
              extraInfo: false,
              showConfirm: true,
              text: 'Deseja mesmo habilitar o grupo?',
              buttonText: 'Habilitar',
              disableInput: true,
            });
            break;
          case GroupDetailsType.DISABLE:
            setOptions({
              extraInfo: false,
              showConfirm: true,
              text: 'Deseja mesmo desabilitar o grupo?',
              buttonText: 'Desabilitar',
              disableInput: true,
            });
            break;    
          default:
            break;
        }
      }, [type]);
      
  if (selectedGroup === undefined) {
    toast.error('Convite inválido');
    cancel();
    return <></>;  }
    
    
      const initialValues = {
        name: selectedGroup.name,
        desc: selectedGroup.desc  
      };

      const handleSubmit = (values: typeof initialValues, actions: any) => {
        if (!user) throw new Error('groupContext not defined');
        if (!user.companyId)
          throw new Error('group companyId on groupContext not defined');
    
        switch (type) {
          case GroupDetailsType.DISABLE:
            toast
              .promise(disableGroup(selectedGroup.id), {
                error: 'Nào foi possivel desativar o grupo, tente mais tarde',
                pending: 'Desativando grupo',
                success: {
                  render: () => {
                    then(selectedGroup);
                    cancel();
                    return 'Grupo desativado';
                  },
                },
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
            break;
          case GroupDetailsType.ENABLE:
            toast
              .promise(enableGroup(selectedGroup.id), {
                error: 'Nào foi possivel Ativar o grupo, tente mais tarde',
                pending: 'Ativando grupo',
                success: {
                  render: () => {
                    then(selectedGroup);
                    cancel();
                    return 'Grupo ativado';
                  },
                },
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
            break;
          default:
            break;
        }
      };

    return(
      <div className="bg-white p-8 rounded md:w-5/12">
      <Formik
         onSubmit={handleSubmit} 
         initialValues={initialValues}
      >
        
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">{options.text}</p>
            <p className="mt-8">Nome:</p>
            <InputText
              name="name"
              placeholder="Digite o nome do grupo"
              isDisabled
            />
            <p className="mt-8">Descrição</p>
            <InputText
              name="description"
              placeholder="Descrição"
              isDisabled
            />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                {options.showConfirm && (
                  <button
                    type="submit"
                    className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                    disabled={isSubmitting}
                  >
                    {options.buttonText}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
);
}

export default GroupDetails;
    



