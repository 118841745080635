import React, { useState } from 'react';
import Navbar from 'Components/Navbar';
import PopUp from 'Components/PopUp';
import AddGroup from 'Components/AddGroup';
import GroupsList from './Groups';
import GroupDetails from 'Components/GroupsDetails';
import { GroupDetailsType } from 'Components/GroupsDetails';
import { GroupModel } from 'utils/api/group';
import UpdateGroup from 'Components/UpdateGroup';

interface GroupProps {}

const Groups: React.FC<GroupProps> = () => {
  const [showAddGroup, setShowAddGroup] = useState(false);

  const [showGroupDetails, setShowGroupDetails] = useState({
    show: false,
    group: undefined as GroupModel | undefined,
    type: GroupDetailsType.DETAIL,
    then: (selectedGroup: GroupModel) => {},
  });

  const [showUpdateGroup, setShowUpdateGroup] = useState(false);
  const [toggleRefreshData, setToggleRefreshData] = useState(false);
  const [currentUpdateGroup, setShowCurrentUpdateGroup] = useState<
    GroupModel | undefined
  >();
  const [tab, setTab] = useState('groups');

  const setAndShowUpdateGroup = (group: GroupModel, callback?: Function) => {
    setShowUpdateGroup(true);
    setShowCurrentUpdateGroup(group);
    callback && callback();
  };

  const handleSelectGroupDetails = (
    group: GroupModel,
    type = GroupDetailsType.DETAIL,
    then = (selectedGroup: GroupModel) => {}
  ) => {
    setShowGroupDetails({ show: true, group, type, then });
  };

  const handleCancel = (refresh = false) => {
    if (refresh) {
      setToggleRefreshData(!toggleRefreshData);
    }
    setShowAddGroup(false);
    setShowUpdateGroup(false);
  };

  return (
    <div>
      <PopUp show={showAddGroup}>
        <AddGroup cancel={handleCancel} />
      </PopUp>
      <PopUp show={showGroupDetails.show}>
        <GroupDetails
          selectedGroup={showGroupDetails.group}
          cancel={() =>
            setShowGroupDetails({ ...showGroupDetails, show: false })
          }
          type={showGroupDetails.type}
          then={showGroupDetails.then}
        />
      </PopUp>
      <PopUp show={showUpdateGroup}>
        <UpdateGroup
          cancel={handleCancel}
          group={currentUpdateGroup}
        />
      </PopUp>
      <Navbar />
      <div className="px-0 md:px-10 mt-7">
        {/* <p className="text-xs">
          <span className="text-primary px-3 ">Grupos</span>
        </p> */}
        <div className="flex items-center justify-between m-2">
          {/* <p className="text-base md:text-3xl mt-4 text-black">Grupos</p> */}
          <div />
          <button
            onClick={() => setShowAddGroup(true)}
            className="text-sm md:text-xl border border-primary-darker p-1 md:p-2 rounded-input text-primary-darker hover:border-primary hover:text-primary"
          >
            Adicionar grupo
          </button>
        </div>
        <div className="shadow px-1 md:px-7 pb-7">
          <div className="flex gap-4 border-b border-lighter-70 py-1 px-3 mb-5 mt-2">
            <button
              className={`${
                tab === 'groups' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('groups')}
            >
              Grupos
            </button>
          </div>
          {tab === 'groups' ? (
            <GroupsList
              selectGroupDetails={handleSelectGroupDetails}
              // key={refreshId}
              setAndShowUpdateGroup={setAndShowUpdateGroup}
              toggleRefreshData={toggleRefreshData}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Groups;
