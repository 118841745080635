import api from './axios';
import { CompanyModel } from './company';

export type MeriMoneySchemeModel = {
  id: string;
  name: string;
  monthlyMeritMoney: number;
  companyId: CompanyModel['id'];
  createdAt: Date;
  type: MeriMoneySchemeModelType;
};

export enum MeriMoneySchemeModelType {
  SIMPLE = 'simple',
}

export const listMeritMoneySchemeByCompany = async (
  companyId: MeriMoneySchemeModel['companyId']
) => {
  const { data } = await api.get<MeriMoneySchemeModel[]>(
    `meritMoneyScheme/byCompany/${companyId}`,
    {}
  );
  return data;
};
