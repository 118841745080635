import React from 'react';

interface TotalizerProps {
  className?: string;
}

const Totalizer: React.FC<TotalizerProps> = ({ className, children }) => {
  return (
    <div className="w-60 h-60 bg-neutral-darker rounded text-center p-3">
      <div className="flex flex-col items-center justify-around w-full h-full">
        {children}
      </div>
    </div>
  );
};

export default Totalizer;
