import React from 'react';

interface SquaresProps {
  className?: string;
  size?: string;
}

const Square: React.FC<SquaresProps> = ({ className, children, size }) => {
  return (
    <div className={`${size}`}>
      <div className={`${className} w-full h-0 pt-full relative`}>
        <div className="flex items-center justify-center absolute w-full h-full transform -translate-y-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Square;
