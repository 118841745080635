import { UserContext } from 'Components/Context/UserContext';
import SelectField from 'Components/Formik/SelectField';
import Totalizer from 'Components/Totalizer';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyTotalizer, getCompanyTotalizer } from 'utils/api/totalizers';
interface CompanyStatisticsProps {}

const CompanyStatistics: React.FC<CompanyStatisticsProps> = () => {
  const { user } = useContext(UserContext);
  const [companyTotalizer, setCompanyTotalizer] = useState<
    CompanyTotalizer | undefined | null
  >();

  useEffect(() => {
    if (!user?.companyId) return console.log('deu ruim');
    getCompanyTotalizer(user.companyId).then(setCompanyTotalizer);
  }, [user]);

  return (
    <div>
      {/* <Formik initialValues={{}} onSubmit={() => {}}>
        {({ submitForm, isSubmitting }) => (
          <SelectField
            name="period"
            options={[
              { value: 'month', label: 'esse mes' },
              { value: 'last7Days', label: 'ultimos 7 dias' },
              { value: 'last30Days', label: 'ultimos 30 dias' },
            ]}
            className="my-3 w-44"
            onChange={submitForm}
            disabled={isSubmitting}
          />
        )}
      </Formik> */}
      <main className="flex items-center">
        <div className="flex flex-wrap gap-5">
          <Totalizer className="w-60 h-60 bg-neutral-darker rounded p-3 text-center">
            <p>Total em carteira de funcionários</p>
            {/* <svg className="w-full" viewBox="0 0 56 14">
              <text x="0" y="15" fill="black"> */}
            <p className="font-bold text-2xl">
              {companyTotalizer?.userWalletTotalizer}
            </p>
            {/* </text>
            </svg> */}
            <div></div>
          </Totalizer>
          <Totalizer>
            <p>Merit money à distribuir </p>
            <p className="font-bold text-2xl">
              {companyTotalizer?.meritMoneyAvailable}
            </p>
            <div></div>
          </Totalizer>
          <Totalizer className="w-60 h-60 bg-neutral-darker rounded p-3 text-center">
            <p>Usuários cadastrados</p>
            {/* <svg className="w-full" viewBox="0 0 56 14">
              <text x="0" y="15" fill="black"> */}
            <p className="font-bold text-2xl">
              {companyTotalizer?.registeredUsers}
            </p>
            {/* </text>
            </svg> */}
            <div></div>
          </Totalizer>
          {/* <Totalizer className="w-60 h-60 bg-neutral-darker rounded p-3 text-center">
            <p>Usuários Ativos</p>
            <p className="font-bold text-2xl">
              {companyTotalizer?.activeUsers}
            </p>
            <div></div>
          </Totalizer> */}
          <Totalizer className="w-60 h-60 bg-neutral-darker rounded p-3 text-center">
            <p>Convites pendentes</p>
            <p className="font-bold text-2xl">
              {companyTotalizer?.pendingInvites}
            </p>
            <div></div>
          </Totalizer>
        </div>
      </main>
    </div>
  );
};

export default CompanyStatistics;
