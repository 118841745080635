import React, { useState } from 'react';
import Navbar from 'Components/Navbar';
import PopUp from 'Components/PopUp';
import AddUser from 'Components/AddUser';
import Invites from './Invites';
import UsersList from './Users';
import { UserInviteModel } from 'utils/api/invites';
import UpdateInvite from 'Components/UpdateInvite';
import UpdateUser from 'Components/UpdateUser';
import UserDetails, { UserDetailsTyoe } from 'Components/UserDetails';
import { UserModel } from 'utils/api/users';
import InvalidateInvite from 'Components/InvalidateInvite';


interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState({
    show: false,
    user: undefined as UserModel | undefined,
    type: UserDetailsTyoe.DETAIL,
    then: (selectedUser: UserModel) => {},
  });
  const [showUpdateInvite, setShowUpdateInvite] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [currentUpdateUser, setShowCurrentUpdateUser] = useState<
  UserModel | undefined
>();
  const [showInvalidateInvite, setShowInvalidateInvite] = useState(false);
  const [toggleRefreshData, setToggleRefreshData] = useState(false);

  const [refreshId, setRefreshId] = useState(0);
  const [currentUpdateInvite, setShowCurrentUpdateInvite] = useState<
    UserInviteModel | undefined
  >();
  const [tab, setTab] = useState('users');


  const setAndShowUpdateInvite = (invite: UserInviteModel) => {
    setShowUpdateInvite(true);
    setShowCurrentUpdateInvite(invite);
  };
  
  const setAndShowUpdateUser = (user: UserModel, callback?: Function) => {
    setShowUpdateUser(true);
    setShowCurrentUpdateUser(user);
    callback && callback();
  };


  const setAndShowInvalidateInvite = (invite: UserInviteModel) => {
    setShowInvalidateInvite(true);
    setShowCurrentUpdateInvite(invite);
  };

  const handleSelectUserDetails = (
    user: UserModel,
    type = UserDetailsTyoe.DETAIL,
    then = (selectedUser: UserModel) => {}
  ) => {
    setShowUserDetails({ show: true, user, type, then });
  };

  const handleCancel = (refresh = false) => {
    if (refresh) {
      setRefreshId(Math.random());
      setToggleRefreshData(!toggleRefreshData)
    }
    setShowUpdateInvite(false);
    setShowInvalidateInvite(false);
    setShowUpdateUser(false);
  };

  
  return (
    <div>
      <PopUp show={showAddUser}>
        <AddUser cancel={() => setShowAddUser(false)} />
      </PopUp>
      <PopUp show={showUserDetails.show}>
        <UserDetails
          selectedUser={showUserDetails.user}
          cancel={() => setShowUserDetails({ ...showUserDetails, show: false })}
          type={showUserDetails.type}
          then={showUserDetails.then}
        />
      </PopUp>
      <PopUp show={showUpdateInvite}>
        <UpdateInvite cancel={handleCancel} invite={currentUpdateInvite} />
      </PopUp>
      <PopUp show={showUpdateUser}>
        <UpdateUser
          cancel={handleCancel}
          updateUser={currentUpdateUser}
        />
      </PopUp>
      <PopUp show={showInvalidateInvite}>
        <InvalidateInvite cancel={handleCancel} invite={currentUpdateInvite} />
      </PopUp>
      <Navbar />
      <div className="px-0 md:px-10 mt-7">
        <p className="text-xs">
          <span className="text-primary px-3 ">Usuários</span>
        </p>
        <div className="flex items-center justify-between m-2">
          <p className="text-base md:text-3xl mt-4 text-black">Usuários</p>
          <button
            onClick={() => setShowAddUser(true)}
            className="text-sm md:text-xl border border-primary-darker p-1 md:p-2 rounded-input text-primary-darker hover:border-primary hover:text-primary"
          >
            Adicionar usuário
          </button>
        </div>

        <div className="mt-12 md:mt-16 shadow px-1 md:px-7 pb-7">
          <div className="flex gap-4 border-b border-lighter-70 py-1 px-3 mb-5 mt-2">
            <button
              className={`${
                tab === 'users' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('users')}
            >
              Usuários
            </button>
            <button
              className={`${
                tab === 'invites' &&
                'border-b border-primary-darker font-semibold text-primary'
              }`}
              onClick={() => setTab('invites')}
            >
              Convites pendentes
            </button>
          </div>
          {tab === 'users' ? (
            <UsersList selectUserDetails={handleSelectUserDetails}
             toggleRefreshData={toggleRefreshData}
             setAndShowUpdateUser={setAndShowUpdateUser} />
          ) : (
            <Invites
              key={refreshId}
              setAndShowUpdateInvite={setAndShowUpdateInvite}
              setAndShowInvalidateInvite={setAndShowInvalidateInvite}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
