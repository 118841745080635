import * as yup from 'yup';

export const disabledUserSchema = yup.object().shape({
  withAccess: yup.boolean(),
  newEmail: yup
    .string()
    .when('withAccess', {
      is: true,
      then: yup.string().email().required('Deve adicionar e-mail pessoal'),
    }),
});
