import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import { updateUserProfile } from 'utils/api/users';
import  InputText from 'Components/Formik/InputText';
import SelectField, { SelectOptions } from 'Components/Formik/SelectField';
import SelectMultiField from './Formik/SelectMultiField';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import { toast } from 'react-toastify';
import { UserModel } from 'utils/api/users'
import { listMeritMoneySchemeByCompany } from 'utils/api/meritMoneyScheme';
import { getCompany } from 'utils/api/company';
import { updateUserByManagerSchema } from 'utils/validators/updateUser';



interface UpdateUserProps {
 updateUser: UserModel | undefined;
  cancel: (refresh?: boolean) => void;
}

const UpdateUser: React.FC<UpdateUserProps> = ({ cancel, updateUser }) => {
  const { user } = useContext(UserContext);

  const [groupsOptions, setGroupsOptions] = useState<SelectOptions>([]);
  const [meritMoneySchemeOptions, setMeritMoneySchemeOptions] = useState<SelectOptions>([]);
  
  useEffect(() => {
    if (!user?.companyId) return;
    getCompany(user?.companyId).then((company) => {
      const groups = company.groups;
      if (groups === undefined) return setGroupsOptions([]);
      const options = groups.map((group) => ({
        label: group.name,
        value: group._id,
      }));
      setGroupsOptions(options);
    });

    listMeritMoneySchemeByCompany(user.companyId).then((meritMoneySchemeList) => {
      const options = meritMoneySchemeList.map((meritMoneyScheme) => ({
        label: meritMoneyScheme.name,
        value: meritMoneyScheme.id,
      }));
      setMeritMoneySchemeOptions(options)
    })
  }, [user]);
 
  const optionsUserProfile = [
    { value: true, label: 'Manager' },
    { value: false, label: 'Comum' },
  ]

  
  if (updateUser === undefined) {
    toast.error('Usuário inválido');
    cancel();
    return <></>;
  }

  const initialValues = {
    meritMoneySchemeId:updateUser?.meritMoneySchemeId,
    groups: [] as SelectOptions,
    manager: updateUser?.manager?.length !== undefined && updateUser.manager.length >= 1 ? true : false
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    
    if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');

    const { meritMoneySchemeId, groups, manager } = values;
    const processedValues = {
      meritMoneySchemeId,
      groups: groups.map((x) => x.value),
      manager,
    };

    const promise = updateUserProfile(updateUser.id, processedValues);
    toast.promise(promise, {
      pending: 'Atualizando usuário',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel(true);
          return 'Usuário atualizado';
        },
      },
      error: {
        render({ data }) {
          actions.setSubmitting(false);
          return 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded md:w-5/12">
      <Formik
        validationSchema={updateUserByManagerSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Editar usuário</p>
            <p className="mt-8">Nome:</p>
            <InputText 
                name="name" 
                placeholder = {updateUser.name}
                disabled
            />
            <p className="mt-8">E-mail:</p>         
            <InputText
              name="email"
              placeholder= {updateUser.email}
              disabled
            />
            <p className="mt-8">Esquema de merit money:</p>
            <SelectField
              name="meritMoneySchemeId"
              placeholder="Selecione um esquema de merit money"
              options={meritMoneySchemeOptions}
              className="mt-2"
            />
            <p className="mt-8">Grupos:</p>
            <SelectMultiField
              name="groups"
              placeholder='Selecione os grupos desejados'
              options={groupsOptions}
              className="mt-2"
              initialValues={updateUser.groups}
            />
            <p className="mt-8">Perfil do usuário:</p>
            <SelectField
              name="manager"
              placeholder='Selecione o perfil do usuário'
              options={optionsUserProfile}
              className="mt-2"
            />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Atualizar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateUser;