import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import SelectField, { SelectOptions } from 'Components/Formik/SelectField';
import { getCompany } from 'utils/api/company';
import InputText from 'Components/Formik/InputText';
import { UserContext } from 'Components/Context/UserContext';
import SelectMultiField from '../Formik/SelectMultiField';
import { enableMission, MissionModel } from 'utils/api/mission';
import DateSelector from 'Components/Formik/DateSelector';

import { listCompanyWallets } from 'utils/api/wallets';

interface EnableMissionProps {
  cancel: (refresh?: boolean) => void;
  mission: MissionModel;
}

const EnableMission: React.FC<EnableMissionProps> = ({ cancel, mission }) => {
  const { user } = useContext(UserContext);

  const [groupsOptions, setGroupsOptions] = useState<SelectOptions>([]);
  const [companyValuesOptions, setCompanyValuesOptions] =
    useState<SelectOptions>([]);
  const [walletOptions, setWalletOptions] = useState<SelectOptions>([]);

  useEffect(() => {
    //get company groups
    if (!user?.companyId) return;
    getCompany(user?.companyId).then((company) => {
      const groups = company.groups;
      if (groups === undefined) return setGroupsOptions([]);
      const groupOptions = groups.map((group) => ({
        label: group.name,
        value: group._id,
      }));
      const values = company.values;
      const companyValuesOptions = values.map((companyValues) => ({
        label: companyValues.name,
        value: companyValues.name,
      }));
      setGroupsOptions(groupOptions);
      setCompanyValuesOptions(companyValuesOptions);
    });

    listCompanyWallets(user?.companyId).then((wallets) => {
      const walletOptions = wallets.map((wallet) => ({
        label: wallet.name,
        value: wallet.id,
      }));
      setWalletOptions([
        {
          label: 'Geral',
          value: 'general',
        },
        ...walletOptions,
      ]);
    });
  }, [user]);

  const initialValues = {
    name: mission?.name,
    description: mission.description,
    companyValues: [] as SelectOptions,
    groups: [] as SelectOptions,
    startsAt: mission.startsAt ? new Date(mission.startsAt) : '',
    endsAt: mission.endsAt ? new Date(mission.endsAt) : '',
    meritus: mission.meritus.toString(),
    approveType: mission.approveType,
    walletId: mission.walletId || 'general',
  };

  const handleSubmit = (values: typeof initialValues, actions: any) => {
    /*  if (!user) throw new Error('userContext not defined');
    if (!user.companyId)
      throw new Error('user companyId on userContext not defined');*/

    const promise = enableMission(mission.id);
    toast.promise(promise, {
      pending: 'Habilitando missão',
      success: {
        render() {
          actions.setSubmitting(false);
          cancel(true);
          return 'Missão habilitada';
        },
      },
      error: {
        render(res: any) {
          actions.setSubmitting(false);
          return res.data.response.data ?? 'Algo deu errado';
        },
      },
    });
  };

  return (
    <div className="bg-white p-8 rounded md:w-5/12">
      <Formik
        // validationSchema={enableMissionSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form className="w-full">
            <p className="text-darker-90 text-2xl">Habilitar missão</p>
            <p className="mt-8">Nome:</p>
            <InputText name="name" placeholder="Digite o nome da missão" disabled/>
            <p className="mt-8">Descrição:</p>
            <InputText name="description" placeholder="Descreva a missão" disabled />
            <p className="mt-8">Quantidade em meritus:</p>
            <InputText name="meritus" placeholder="Digite o valor" disabled />
            <p className="mt-8">Data de início:</p>
            <DateSelector name="startsAt" className="text-xs z-50" isDisabled />
            <p className="mt-8">Data de finalização:</p>
            <DateSelector name="endsAt" className="text-xs z-40" isDisabled />
            <p className="mt-8">Tipo da missão:</p>
            <SelectField
              disabled
              name="approveType"
              placeholder="Selecione o tipo da missão"
              options={[
                {
                  label: 'Com evidência',
                  value: 'proof',
                },
                {
                  label: 'Sem evidência',
                  value: 'simple',
                },
              ]}
              className="mt-2"
            />
            <p className="mt-8">Valores:</p>
            <SelectMultiField
              disabled
              name="companyValues"
              options={companyValuesOptions}
              className="mt-2"
              initialValues={mission.companyValues}
            />
            <p className="mt-8">Grupos:</p>
            <SelectMultiField
              disabled
              name="groups"
              options={groupsOptions}
              className="mt-2"
              initialValues={mission.groups.map((x) => x.id)}
            />
            <p className="mt-8">Carteira:</p>
            <SelectField
            disabled
              name="walletId"
              placeholder="Selecione a carteira"
              options={walletOptions}
              className="mt-2"
            />
            <hr className="mt-8 border-lighter-70" />
            <div className="flex items-center justify-between mt-4">
              <div></div>
              <div className="flex items-center gap-2">
                <button
                  className="px-7 py-2 border rounded-full"
                  onClick={() => cancel()}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-7 py-2 bg-primary-darker text-white rounded-full disabled:opacity-30"
                  disabled={isSubmitting}
                >
                  Habilitar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EnableMission;
